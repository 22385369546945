<h1>
  <ellipse-icon *ngIf="dynamicForm.labelIcon as iconName" [iconName]="dynamicForm.labelIcon"></ellipse-icon>
  {{ dynamicForm.label }}
</h1>
<ng-container *ngFor="let element of dynamicForm.elements">
  <ng-container [ngSwitch]="element.elementType" *ngIf="element.exists">
    <ellipse-dynamic-form-field-container *ngSwitchCase="DynamicFormElementType.FIELD"
                                          [dynamicFormField]="getDynamicFormField(element)"
                                          [viewMode]="viewMode"/>
    <ellipse-dynamic-form-fields-container *ngSwitchCase="DynamicFormElementType.GROUP"
                                           [dynamicFormElement]="getDynamicFormGroup(element)"
                                           [viewMode]="viewMode" />
    <ellipse-dynamic-form-fields-container *ngSwitchCase="DynamicFormElementType.ARRAY"
                                           [dynamicFormElement]="getDynamicFormArray(element)"
                                           [viewMode]="viewMode" />
  </ng-container>
</ng-container>
<ng-container *ngIf="viewMode === 'form'">
<button *ngIf="dynamicForm.buttons.submit as submitButton" mat-flat-button (click)="onSubmitForm()" color="primary"
        class="submit-button">{{ submitButton.label }}</button>
</ng-container>
