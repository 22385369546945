<mat-form-field *ngIf="viewMode === 'form'">
  <mat-label>{{ dynamicFormField.label }}</mat-label>
  <input type="date"
         matInput
         [formControl]="dynamicFormField.formControl"
         [matDatepicker]="picker"
         [readonly]="dynamicFormField.readonly">
  <mat-error *ngIf="errorMsg$ | async as errorMsg">{{ errorMsg }}</mat-error>
  <mat-datepicker-toggle matIconSuffix [for]="picker" *ngIf="dynamicFormField.showDatepicker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
</mat-form-field>
<ellipse-dynamic-summary-field *ngIf="viewMode === 'summary'"
                               [label]="dynamicFormField.label"
                               [value]="summaryFormattedDate"/>
