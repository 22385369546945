import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CalculatorInputValue } from '../calculator-input-value.type';

@Component({
  selector: 'ellipse-calculator-input',
  templateUrl: './calculator-input.component.html',
  styleUrls: ['./calculator-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CalculatorInputComponent),
      multi: true,
    },
  ],
})
export class CalculatorInputComponent implements ControlValueAccessor {

  @Input() label!: string;
  @Output() calculatorModalOpen = new EventEmitter<CalculatorInputValue>();

  scoreControl = new FormControl(0);

  val: CalculatorInputValue = {
    score: 0,
    fields: {},
    checked: []
  };

  set value(val: CalculatorInputValue | null) {
    if (val) {
      this.val = val;
      this.scoreControl.patchValue(val.score);
      this.onChange(val);
      this.onTouch(val);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(obj: CalculatorInputValue | null): void {
    this.value = obj;
  }

  onChange = (val: CalculatorInputValue | null) => {}
  onTouch = (val: CalculatorInputValue | null) => {};

  onOpenCalculatorModal(event: MouseEvent) {
    event.stopPropagation();
    this.calculatorModalOpen.emit(this.val);
  }
}
