import { FormArray, FormGroup } from '@angular/forms';
import { DynamicFormGroupConfig } from './dynamic-form-group-config.model';
import { DynamicFormElement } from '../dynamic-form-element';
import { DynamicFormGeneratorUtils } from '../../utils/dynamic-form-generator.utils';
import { DynamicFormUtils } from '../../utils/dynamic-form.utils';
import { combineLatest, Observable, startWith, takeUntil, tap } from 'rxjs';

export class DynamicFormGroup extends DynamicFormElement {
  public formGroup!: FormGroup;
  public children!: DynamicFormElement[];

  public originalLabel!: string;
  public originalDescription!: string;

  private constructor(config: DynamicFormGroupConfig, public parentForm: FormGroup | FormArray) {
    super(config);
    this.setupForm(config, parentForm);
    this.buildChildElements(config, this.formGroup);
    this.storeOriginalLabelAndDescription();
    this.attachLabelAndDescriptionListeners(this.originalLabel, this.originalDescription);
  }

  private storeOriginalLabelAndDescription() {
    this.originalLabel = this.label ?? '';
    this.originalDescription = this.description ?? '';
  }

  private attachLabelAndDescriptionListeners(label: string, description: string) {
    const fieldNames = DynamicFormUtils.getInterpolatedWordsFromText(`${label} ${description}`);
    if (fieldNames.length > 0) {
      combineLatest(fieldNames.map(fieldName => this.getValueChangesObservableForFieldName(fieldName))).pipe(
        takeUntil(this.destroy$),
        tap(() => this.buildLabelAndDescription(this.originalLabel, this.originalDescription))
      ).subscribe();
    }
  }

  private getValueChangesObservableForFieldName(fieldName: string): Observable<any> {
    const control = this.formGroup.get(fieldName)!
    return control.valueChanges.pipe(
      startWith(control.value)
    );
  }

  static create(config: DynamicFormGroupConfig, parentForm: FormGroup | FormArray): DynamicFormGroup {
    return new DynamicFormGroup(config, parentForm);
  }

  clearAllChildren() {
    DynamicFormUtils.clear(this.children);
  }

  override revert() {
    this.children.forEach(child => child.revert());
  }

  override hasError(): boolean {
    return this.formGroup.touched && this.formGroup.invalid;
  }

  override removeSelf() {
    super.removeSelf();
    DynamicFormGeneratorUtils.removeFormElementFromParent(this.name, this.parentForm as FormGroup);
    this.parentForm.updateValueAndValidity();
  }

  override addSelf() {
    super.addSelf();
    DynamicFormGeneratorUtils.addFormElementToParent(this.formGroup, this.name, this.parentForm);
    this.revert();
    this.formGroup.markAsUntouched();
    this.parentForm.updateValueAndValidity();
  }

  /**
   * Finds any occurrences of interpolated strings and replaces them with the correct values
   * @private
   */
  private buildLabelAndDescription(label: string, description: string) {
    this.label = DynamicFormUtils.replaceInterpolatedTextWithFormFieldValues(label, this.formGroup);
    if (this.description) {
      this.description = DynamicFormUtils.replaceInterpolatedTextWithFormFieldValues(description, this.formGroup);
    }
  }

  private buildChildElements(config: DynamicFormGroupConfig, formGroup: FormGroup) {
    const preconfiguredChildren = DynamicFormGeneratorUtils.preconfigureChildren(config);
    this.children = DynamicFormGeneratorUtils.generateChildren(preconfiguredChildren, formGroup);
  }

  private setupForm(config: DynamicFormGroupConfig, parentForm: FormGroup | FormArray) {
    this.createMyFormGroup();
    this.addMyFormGroupToParentForm(config, parentForm);
  }

  private addMyFormGroupToParentForm(config: DynamicFormGroupConfig, parentForm: FormGroup<any> | FormArray<any>) {
    DynamicFormGeneratorUtils.addFormElementToParent(this.formGroup, config.name, parentForm);
  }

  private createMyFormGroup() {
    this.formGroup = new FormGroup({});
  }
}
