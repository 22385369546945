import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromUi from './ui.reducer';

export const selectUiState = createFeatureSelector<fromUi.UiState>(fromUi.featureKey);

export const selectMenuOpen = createSelector(
  selectUiState,
  state => state.menuOpen
);

export const selectMainNavMenuItems = createSelector(
  selectUiState,
  state => state.mainNavMenuItems.map(item => {
    switch (item.route) {
      case 'access-requests':
        return { ...item, badge: state.pendingAccessRequests };
      case 'research-projects':
        return { ...item, badge: state.newResearchProjects };
      default:
        return item;
    }
  })
);

export const selectPendingAccessRequests = createSelector(
  selectUiState,
  state => state.pendingAccessRequests
);
