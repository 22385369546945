import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DynamicFormField } from '../../../../models';
import { Validators } from '@angular/forms';
import { map, Observable, Subject } from 'rxjs';
import { ViewMode } from '../../../../models/view-mode.type';

@Component({
  selector: 'ellipse-base-dynamic-field',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BaseDynamicFieldComponent implements OnInit, OnDestroy {

  required!: boolean;
  errorMsg$!: Observable<string>;
  destroy$!: Subject<boolean>;
  @Input() dynamicFormField!: DynamicFormField;
  @Input() viewMode: ViewMode = 'form';

  ngOnInit() {
    this.required = this.controlIsRequired(this.dynamicFormField);
    this.errorMsg$ = this.configureErrorMessageListener(this.dynamicFormField);
    this.destroy$ = new Subject<boolean>();
  }

  private controlIsRequired(dynamicFormField: DynamicFormField): boolean {
    return dynamicFormField.formControl.hasValidator(Validators.required)
  }

  private configureErrorMessageListener(dynamicFormField: DynamicFormField): Observable<string> {
    return dynamicFormField.formControl.statusChanges.pipe(
      map(status => status === 'VALID' ? '' : this.buildErrorMessage(this.dynamicFormField))
    );
  }

  private buildErrorMessage(dynamicFormField: DynamicFormField): string {
    const errorKeys = Object.keys(dynamicFormField.formControl.errors!);
    const concatenatedErrors = errorKeys
      .map(errorKey => dynamicFormField.validatorErrorMessageMap[errorKey])
      .join(', ');
    return concatenatedErrors || 'Champ invalide';
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }
}
