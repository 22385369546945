<mat-card>
  <mat-card-header>
    @if (dynamicFormElement.label; as label) {
      <mat-card-title [ngClass]="{ 'error-text': dynamicFormElement.hasError() }">
        @if (dynamicFormElement.labelIcon; as iconName) {
          <ellipse-icon [iconName]="iconName"/>
        }
        {{ label }}
      </mat-card-title>
    }

    @if (dynamicFormElement.description; as description) {
      <mat-card-subtitle>{{ description }}</mat-card-subtitle>
    }

  </mat-card-header>
  <mat-card-content>
    <div class="card-content">
      <ng-content select="[formContent]"/>
    </div>
    <div class="array-item-actions">
      <ng-content select="[arrayItemActions]"/>
    </div>
  </mat-card-content>
  <mat-card-actions align="end">
    <ng-content select="[formArrayActions]"/>
  </mat-card-actions>
</mat-card>
