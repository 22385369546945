import { DynamicFormElementConfig } from './dynamic-form-element-config.model';
import { DynamicFormElementType } from './dynamic-form-element-type.enum';
import { Observable, Subject } from 'rxjs';
import { DynamicFormElementValidatorConfig } from '../dynamic-form-validators';
import { DynamicFormElementTemplateType } from './dynamic-form-element-template-type.enum';

export abstract class DynamicFormElement {
  public name!: string;
  public elementType!: DynamicFormElementType;
  public label!: string;
  public validators!: DynamicFormElementValidatorConfig[];
  public readonly?: boolean;
  public disabled?: boolean;
  public cssClasses?: string[];
  public width!: number;
  public tooltip?: string;
  public description?: string;
  public closable!: boolean;
  public template!: DynamicFormElementTemplateType;
  public exists: boolean = true;
  public wholeRow?: boolean;
  public labelIcon?: string;
  public hideInSummaryIfNull!: boolean;

  public errorMsg$!: Observable<string>;

  private _destroy$!: Subject<boolean>;
  protected get destroy$() { return this._destroy$.asObservable() }

  private DEFAULTS = {
    template: DynamicFormElementTemplateType.EXPANSION_PANEL,
    closable: true,
    width: 100,
    label: ''
  };

  protected constructor(config: DynamicFormElementConfig) {
    this.createDestroySubject();
    this.setBaseFields(config);
    this.setFormControlFields(config);
    this.setStyleFields(config);
    this.setTemplateFields(config);
    this.setLabelIconField(config);
  }

  hasError(): boolean {
    return false;
  }

  removeSelf() {
    this.exists = false;
  }

  addSelf() {
    this.exists = true;
  }

  revert() {}

  destroy() {
    this._destroy$.next(true);
  }

  private setBaseFields(config: DynamicFormElementConfig) {
    this.name = config.name;
    this.label = config.label || this.DEFAULTS.label;
    this.elementType = config.elementType;
    this.description = config.description;
    this.tooltip = config.tooltip;
    this.hideInSummaryIfNull = config.hideInSummaryIfNull ?? false;
  }

  private createDestroySubject() {
    this._destroy$ = new Subject<boolean>();
  }

  private setLabelIconField(config: DynamicFormElementConfig) {
    if (config.labelIcon) {
      this.labelIcon = config.labelIcon;
    }
  }

  private setTemplateFields(config: DynamicFormElementConfig) {
    this.closable = config.closable ?? this.DEFAULTS.closable;
    this.template = config.template ?? this.DEFAULTS.template;
  }

  private setFormControlFields(config: DynamicFormElementConfig) {
    this.validators = config.validators ?? [];
    this.readonly = config.readonly;
    this.disabled = config.disabled;
  }

  private setStyleFields(config: DynamicFormElementConfig) {
    this.cssClasses = config.cssClasses;
    this.width = config.width || this.DEFAULTS.width;
    this.wholeRow = !!config.wholeRow;
  }
}
