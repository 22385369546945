import { DynamicFormFieldValidator } from '../dynamic-form-element-validator.model';
import { DynamicFormElementValidatorConfig } from '../dynamic-form-element-validator-config.model';
import { Validators } from '@angular/forms';

export class RequiredValidator extends DynamicFormFieldValidator {

  defaultErrorMsg = 'Ce champ est requis.';

  constructor(config: DynamicFormElementValidatorConfig) {
    super(config);
    this.setType(config.type);
    this.configureError(config);
    this.setValidatorFn(Validators.required);
  }

  configureError(config: DynamicFormElementValidatorConfig) {
    this.errorMsg = config.errorMsg ?? this.defaultErrorMsg;
    this.errorKey = 'required';
  }

}
