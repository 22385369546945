import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'ellipse-dynamic-form-array-actions',
  templateUrl: './dynamic-form-array-actions.component.html',
  styleUrls: ['./dynamic-form-array-actions.component.scss']
})
export class DynamicFormArrayActionsComponent {

  @Output() addChild = new EventEmitter<boolean>();
  constructor() { }

  onAddChild() {
    this.addChild.emit(true);
  }
}
