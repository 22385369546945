import { DynamicFormFieldWithOptions } from '../dynamic-form-field-with-options';
import { DynamicFormFieldConfig } from '../dynamic-form-field-config.model';
import { FormArray, FormGroup } from '@angular/forms';
import {
  DynamicAutocompleteFieldComponent
} from '../../../components/dynamic-form/dynamic-form-field';

export class AutocompleteField extends DynamicFormFieldWithOptions {
  constructor(config: DynamicFormFieldConfig, parentForm: FormGroup | FormArray) {
    super(config, parentForm);
    this.component = DynamicAutocompleteFieldComponent;
  }

  static override create(config: DynamicFormFieldConfig, parentForm: FormGroup | FormArray) {
    return new AutocompleteField(config, parentForm);
  }
}
