import { Component } from '@angular/core';
import {
  BaseDynamicFieldWithOptionsComponent
} from '../base-dynamic-field-with-options/base-dynamic-field-with-options.component';

@Component({
  selector: 'ellipse-dynamic-selection-list-field',
  templateUrl: './dynamic-selection-list-field.component.html',
  styleUrls: ['./dynamic-selection-list-field.component.scss']
})
export class DynamicSelectionListFieldComponent extends BaseDynamicFieldWithOptionsComponent {
}
