import { Permission } from 'types-unicorn';
import {
  DynamicFormArrayConfig,
  DynamicFormConfig,
  DynamicFormElementConfig,
  DynamicFormElementType,
  DynamicFormFieldConfig,
  DynamicFormFieldOptionList,
  DynamicFormGroupConfig
} from '../models';

export class DynamicFormConfigBuilder {
  constructor(private _config: DynamicFormConfig) {
  }

  build(): DynamicFormConfig {
    return this._config;
  }

  withOptionLists(optionLists: DynamicFormFieldOptionList): DynamicFormConfigBuilder {
    this._config.elementConfigs = this.addOptionsToElements(this._config.elementConfigs, optionLists);
    return this;
  }

  withPermissions(permissions: Permission[]): DynamicFormConfigBuilder {
    this._config.elementConfigs = this.applyPermissionsToElements(this._config.elementConfigs, permissions);
    return this;
  }

  withFormTitle(title: string): DynamicFormConfigBuilder {
    this._config.label = title;
    return this;
  }

  withCustomProperties(customProperties: { [key: string]: any }): DynamicFormConfigBuilder {
    this._config.customProperties = customProperties;
    return this;
  }

  private addOptionsToElements(elementConfigs: DynamicFormElementConfig[], optionLists: DynamicFormFieldOptionList): DynamicFormElementConfig[] {
    return elementConfigs.map(config => {
      return this.addOptionsToElementByType(config, optionLists);
    });
  }

  private addOptionsToElementByType(config: DynamicFormElementConfig, optionLists: DynamicFormFieldOptionList) {
    switch (config.elementType) {
      case DynamicFormElementType.FIELD:
        return this.addOptionsToField(config as DynamicFormFieldConfig, optionLists);
      case DynamicFormElementType.GROUP:
        return this.addOptionsToGroup(config as DynamicFormGroupConfig, optionLists);
      case DynamicFormElementType.ARRAY:
        return this.addOptionsToArray(config as DynamicFormArrayConfig, optionLists);
      default:
        return config;
    }
  }

  private addOptionsToField(config: DynamicFormFieldConfig, optionLists: DynamicFormFieldOptionList) {
    const fieldConfig = config;
    if (fieldConfig.listUniqueKey && optionLists[fieldConfig.listUniqueKey]) {
      return { ...config, options: optionLists[fieldConfig.listUniqueKey] };
    }
    return config;
  }

  private addOptionsToGroup(config: DynamicFormGroupConfig, optionLists: DynamicFormFieldOptionList): DynamicFormGroupConfig {
    return {
      ...config,
      children: this.addOptionsToElements(config.children, optionLists),
    };
  }

  private addOptionsToArray(config: DynamicFormArrayConfig, optionLists: DynamicFormFieldOptionList): DynamicFormArrayConfig {
    const updatedConfig = { ...config };
    updatedConfig.children = this.addOptionsToElements(updatedConfig.children ?? [], optionLists);
    updatedConfig.childPrototypeConfig = this.addOptionsToElementByType(updatedConfig.childPrototypeConfig, optionLists) as DynamicFormFieldConfig | DynamicFormGroupConfig;
    return updatedConfig;
  }

  private applyPermissionsToElements(elementConfigs: DynamicFormElementConfig[], permissions: Permission[]): DynamicFormElementConfig[] {
    const unfilteredElements = elementConfigs.map(config => {
      switch (config.elementType) {
        case DynamicFormElementType.ARRAY:
        case DynamicFormElementType.GROUP:
          const elementConfig = this.applyReadonlyToElement(config, permissions) as DynamicFormGroupConfig | DynamicFormArrayConfig;
          const unfilteredChildren = this.applyPermissionsToElements(elementConfig.children, permissions);
          const filteredChildren = this.filterElementsForPermissions(unfilteredChildren, permissions);
          return {
            ...elementConfig,
            children: filteredChildren,
          }
        case DynamicFormElementType.FIELD:
          return this.applyReadonlyToElement(config, permissions) as DynamicFormFieldConfig;
      }
    });
    return this.filterElementsForPermissions(unfilteredElements, permissions);
  }

  private filterElementsForPermissions(elementConfigs: DynamicFormElementConfig[], permissions: Permission[]): DynamicFormElementConfig[] {
    return elementConfigs.filter(config => {
      const configRequiresPermissions = !!config.showForPermissions && config.showForPermissions.length > 0;
      return !configRequiresPermissions || this.userHasPermission(config.showForPermissions as Permission[], permissions);
    });
  }

  private userHasPermission(requiredPermissions: Permission[], userPermissions: Permission[]) {
    return requiredPermissions.some(permission => userPermissions.includes(permission));
  }

  private applyReadonlyToElement(config: DynamicFormElementConfig, permissions: Permission[]): DynamicFormElementConfig {
    const configRequiresPermissionsToEdit = !!config.editableForPermissions && config.editableForPermissions.length > 0;
    return {
      ...config,
      readonly: config.readonly || configRequiresPermissionsToEdit && !this.userHasPermission(config.editableForPermissions as Permission[], permissions)
    };
  }
}
