import { DynamicFormField } from '../dynamic-form-field.model';
import { DynamicFormFieldConfig } from '../dynamic-form-field-config.model';
import { FormArray, FormGroup } from '@angular/forms';
import { DynamicTextareaFieldComponent } from '../../../components/dynamic-form/dynamic-form-field';

export class TextareaField extends DynamicFormField {
  constructor(config: DynamicFormFieldConfig, parentForm: FormGroup | FormArray) {
    super(config, parentForm);
    this.component = DynamicTextareaFieldComponent;
  }

  static override create(config: DynamicFormFieldConfig, parentForm: FormGroup | FormArray) {
    return new TextareaField(config, parentForm);
  }
}
