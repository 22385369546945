import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'ellipse-calculator-modal',
  templateUrl: './calculator-modal.component.html',
  styleUrls: ['./calculator-modal.component.scss']
})
export class CalculatorModalComponent implements OnInit {

  calculatorForm!: FormGroup;
  checkboxes: { label: string, value: number }[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: { fields: { [label: string]: number }, checked: string[] },
              private formBuilder: FormBuilder,
              private dialogRef: MatDialogRef<CalculatorModalComponent>) {
  }

  ngOnInit() {
    this.buildForm();
  }

  onConfirm() {
    const result = this.getResultFromForm();
    this.dialogRef.close(result);
  }

  onCancel() {
    this.dialogRef.close();
  }

  private getResultFromForm() {
    return {
      fields: this.data.fields,
      checked: Object.entries(this.calculatorForm.value)
        .map(([label, checked]) => checked ? label : undefined)
        .filter(label => !!label)
    };
  }

  private buildForm() {
    this.calculatorForm = this.formBuilder.group({});
    Object.entries(this.data.fields).forEach(([label, value]) => {
      this.checkboxes.push({ label, value });
      this.calculatorForm.addControl(label, this.formBuilder.control(this.data.checked.includes(label)));
    });
  }
}
