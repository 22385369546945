import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { BaseDynamicFieldComponent } from '../base-dynamic-field/base-dynamic-field.component';
import { DateUtils } from '../../../../utils/date.utils';

@Component({
  selector: 'ellipse-dynamic-date-field',
  templateUrl: './dynamic-date-field.component.html',
  styleUrls: ['./dynamic-date-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicDateFieldComponent extends BaseDynamicFieldComponent implements OnInit {

  summaryFormattedDate!: string;

  override ngOnInit() {
    super.ngOnInit();
    if (this.viewMode === 'summary') {
      this.generateSummaryFormattedDate();
    }
  }

  private generateSummaryFormattedDate() {
    if (this.dynamicFormField.formControl.value) {
      this.summaryFormattedDate = DateUtils.getDisplayString(this.dynamicFormField.formControl.value);
    }
  }
}
