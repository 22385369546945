import { DynamicFormElementConfig } from '../dynamic-form-element';
import { DynamicFormFieldOption } from './dynamic-form-field-with-options';
import { DynamicFormFieldType } from './dynamic-form-field-type.enum';

export class DynamicFormFieldConfig extends DynamicFormElementConfig {
  public fieldType!: DynamicFormFieldType;
  public options?: DynamicFormFieldOption[];
  public listUniqueKey?: string;
  public defaultValue?: any;
  public value?: any;
  public showOptionTooltips?: boolean;
  public parentControlName?: string;
  public numbersOnly?: boolean;
  public multiSelect?: boolean;
  public showDatepicker?: boolean;
}
