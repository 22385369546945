import { createFeature, createReducer } from '@ngrx/store';

export const featureKey = 'core';

export interface CoreState {
  loading: boolean,
}

export const initialState: CoreState = {
  loading: false,
}

export const coreFeature = createFeature({
  name: featureKey,
  reducer: createReducer(
    initialState,
  )
});
