import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BaseDynamicFieldComponent } from '../base-dynamic-field/base-dynamic-field.component';

@Component({
  selector: 'ellipse-dynamic-number-field',
  templateUrl: './dynamic-number-field.component.html',
  styleUrls: ['./dynamic-number-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicNumberFieldComponent extends BaseDynamicFieldComponent {

}
