import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { DynamicFormConfig } from 'dynamic-form';

@Component({
  selector: 'bna-form-container',
  templateUrl: './form-container.component.html',
  styleUrls: ['./form-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormContainerComponent {

  @Input() formConfig!: DynamicFormConfig;
  @Input() readonly = false;
  @Input() formValue: { [key: string]: any } = {};
  @Input() viewMode: 'form' | 'summary' = 'form';

  @Output() formValueChange = new EventEmitter<{ [p: string]: any }>();
  @Output() formSubmit = new EventEmitter<{ [p: string]: any }>();

  constructor() { }

  formValueChanged(formValue: { [p: string]: any }) {
    this.formValueChange.emit(formValue);
  }

  formSubmitted(formValue: { [p: string]: any }) {
    this.formSubmit.emit(formValue);
  }
}
