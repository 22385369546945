import { DynamicFormElementValidatorType, DynamicFormFieldValidator } from './index';
import {
  MaxDateValidator,
  MaxIntervalAfterTodayValidator,
  MaxIntervalBeforeTodayValidator,
  MaxLengthValidator,
  MaxValueValidator,
  MinDateValidator,
  MinIntervalAfterTodayValidator,
  MinIntervalBeforeTodayValidator,
  MinLengthValidator,
  MinValueValidator,
  NoDuplicateValueCombinationsValidator,
  NoDuplicateValuesValidator,
  RequiredValidator
} from './validator-types';

export const validatorTypeDictionary: { [property in DynamicFormElementValidatorType]: typeof DynamicFormFieldValidator } = {
  [DynamicFormElementValidatorType.REQUIRED]: RequiredValidator,
  [DynamicFormElementValidatorType.MIN_VALUE]: MinValueValidator,
  [DynamicFormElementValidatorType.MAX_VALUE]: MaxValueValidator,
  [DynamicFormElementValidatorType.MIN_DATE]: MinDateValidator,
  [DynamicFormElementValidatorType.MAX_DATE]: MaxDateValidator,
  [DynamicFormElementValidatorType.MIN_LENGTH]: MinLengthValidator,
  [DynamicFormElementValidatorType.MAX_LENGTH]: MaxLengthValidator,
  [DynamicFormElementValidatorType.MIN_INTERVAL_BEFORE_TODAY]: MinIntervalBeforeTodayValidator,
  [DynamicFormElementValidatorType.MAX_INTERVAL_BEFORE_TODAY]: MaxIntervalBeforeTodayValidator,
  [DynamicFormElementValidatorType.MIN_INTERVAL_AFTER_TODAY]: MinIntervalAfterTodayValidator,
  [DynamicFormElementValidatorType.MAX_INTERVAL_AFTER_TODAY]: MaxIntervalAfterTodayValidator,
  [DynamicFormElementValidatorType.NO_DUPLICATE_VALUES_FOR_CONTROL]: NoDuplicateValuesValidator,
  [DynamicFormElementValidatorType.NO_DUPLICATE_VALUE_COMBINATIONS_FOR_CONTROLS]: NoDuplicateValueCombinationsValidator
};
