import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import {
  DynamicForm,
  DynamicFormArray,
  DynamicFormConfig,
  DynamicFormElement,
  DynamicFormElementType,
  DynamicFormField,
  DynamicFormGroup
} from '../../models';
import { ReplaySubject, Subject, takeUntil, tap } from 'rxjs';
import { ViewMode } from '../../models/view-mode.type';

@Component({
  selector: 'ellipse-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class DynamicFormComponent implements OnInit, OnDestroy {

  DynamicFormElementType = DynamicFormElementType;
  dynamicForm!: DynamicForm;
  @Input() dynamicFormConfig!: DynamicFormConfig;
  @Input() readonly?: boolean;
  @Input() viewMode: ViewMode = 'form';
  @Input() set formValue(value: any) {
    this.patchQueue$.next(value);
  }
  @Output() formValueChange = new EventEmitter<{ [key: string]: any }>();
  @Output() submitForm = new EventEmitter<{ [key: string]: any }>();
  private destroy$!: Subject<boolean>;
  private patchQueue$ = new ReplaySubject<any>();

  constructor(private zone: NgZone) {}

  ngOnInit() {
    this.destroy$ = new Subject<boolean>();
    this.buildDynamicForm();
    this.connectPatchQueueListener();
    this.disableIfReadonly();
    this.connectRootListener();
  }

  onSubmitForm() {
    this.submitForm.emit(this.dynamicForm.rootFormGroup.value);
  }

  getDynamicFormGroup(element: DynamicFormElement): DynamicFormGroup {
    return element as DynamicFormGroup;
  }

  getDynamicFormArray(element: DynamicFormElement): DynamicFormArray {
    return element as DynamicFormArray;
  }

  getDynamicFormField(element: DynamicFormElement): DynamicFormField {
    return element as DynamicFormField;
  }

  /**
   * Building the form outside of the Angular Zone allows us to build and populate the entire form without triggering change detection
   *
   * Once the form is complete, it is assigned (and disabled if necessary) back inside the Angular Zone
   * @private
   */
  private buildDynamicForm() {
    this.zone.runOutsideAngular(() => {
      const form = DynamicForm.create(this.dynamicFormConfig);
      this.zone.run(() => {
        this.dynamicForm = form;
        this.disableIfReadonly();
      });
    });
  }

  private disableIfReadonly() {
    if (this.readonly) {
      this.dynamicForm.rootFormGroup.disable();
    }
  }

  private connectRootListener() {
    this.dynamicForm.rootFormGroup.valueChanges.pipe(
      takeUntil(this.destroy$),
      tap(formValue => this.formValueChange.emit(formValue))
    ).subscribe();
  }

  ngOnDestroy() {
    this.dynamicForm.destroy();
    this.destroy$.next(true);
  }

  private connectPatchQueueListener() {
    this.patchQueue$.pipe(
      takeUntil(this.destroy$),
      tap(value => {
        this.dynamicForm.patch(value);
      })
    ).subscribe()
  }
}
