import { DynamicFormFieldValidator } from '../dynamic-form-element-validator.model';
import { DynamicFormElementValidatorConfig } from '../dynamic-form-element-validator-config.model';
import { DynamicFormElementValidatorType } from '../dynamic-form-element-validator-type.enum';
import { DateUtils } from '../../../utils/date.utils';
import { minDate } from '../custom-validator-functions';

export class MaxIntervalBeforeTodayValidator extends DynamicFormFieldValidator {

  defaultErrorMsg = 'La date ne doit pas être avant le {{value}}';

  constructor(config: DynamicFormElementValidatorConfig) {
    super(config);
    this.setType(DynamicFormElementValidatorType.MAX_INTERVAL_BEFORE_TODAY);
    this.setValue(DateUtils.getDateFormatString(DateUtils.todayMinusDuration(DateUtils.intervalStringToDurationObject(config.value))));
    this.setDisplayValue(DateUtils.getDisplayString(DateUtils.todayMinusDuration(DateUtils.intervalStringToDurationObject(config.value))))
    this.configureError(config);
    this.setValidatorFn(minDate(this.value));
  }

  configureError(config: DynamicFormElementValidatorConfig) {
    this.errorMsg = config.errorMsg ?? this.defaultErrorMsg;
    this.interpolateValueInErrorMessage(this.displayValue);
    this.errorKey = 'mindate';
  }

}
