import { DynamicFormElementConfig } from './dynamic-form-element';
import { DynamicFormLink } from './dynamic-form-links';

export class DynamicFormConfig {
  label!: string;
  name!: string;
  elementConfigs!: DynamicFormElementConfig[];
  buttons!: {
    submit: {
      label: string,
      position?: string
    }
  };
  links?: DynamicFormLink[];
  labelIcon?: string;
  customProperties?: { [key: string]: string | number | boolean };
}
