import { DynamicFormArrayConfig } from './dynamic-form-array-config.model';
import { FormArray, FormGroup } from '@angular/forms';
import { DynamicFormElement, DynamicFormElementConfig, DynamicFormElementType } from '../dynamic-form-element';
import { DynamicFormGeneratorUtils } from '../../utils/dynamic-form-generator.utils';
import { DynamicFormGroup, DynamicFormGroupConfig } from '../dynamic-form-group';
import { DynamicFormField, DynamicFormFieldConfig } from '../dynamic-form-field';
import { DynamicFormElementValidatorFactory } from '../dynamic-form-validators/dynamic-form-element-validator.factory';
import { map, startWith } from 'rxjs';
import { DynamicFormArrayError } from './errors/dynamic-form-array.error';
import { DynamicFormUtils } from '../../utils/dynamic-form.utils';

export class DynamicFormArray extends DynamicFormElement {
  formArray!: FormArray;
  children!: DynamicFormElement[];
  childPrototypeConfig!: DynamicFormGroupConfig | DynamicFormFieldConfig;
  initialChildren!: DynamicFormElementConfig[];

  private constructor(config: DynamicFormArrayConfig, public parentForm: FormGroup | FormArray) {
    super(config);
    this.setupForm(config, parentForm);
    this.buildChildren(config);
    this.configureInitialState(config);
    this.setErrorMessage();
  }

  private setErrorMessage() {
    this.errorMsg$ = this.formArray.statusChanges.pipe(
      startWith(this.formArray.status),
      map(status => status === 'VALID' ? '' : this.compileErrorMessage()),
    );
  }

  private compileErrorMessage(): string {
    const errors: DynamicFormArrayError[] = Object.values(this.formArray.errors || {});
    return errors
      .map(error => error.compileMessage(this))
      .join('\n');
  }

  static create(config: DynamicFormArrayConfig, parentForm: FormGroup | FormArray) {
    return new DynamicFormArray(config, parentForm);
  }

  findChildFieldConfigWithName(config: DynamicFormElementConfig, name: string): DynamicFormFieldConfig | null {
    if (DynamicFormUtils.isDynamicFormFieldConfig(config)) {
      return config.name === name ? config : null;
    }
    if (DynamicFormUtils.isDynamicFormGroupConfig(config)) {
      const children = config.children.map(child => this.findChildFieldConfigWithName(child, name)).filter(child => child !== null);
      return children.length === 1 ? children[0] : null;
    }
    if (DynamicFormUtils.isDynamicFormArrayConfig(config)) {
      return this.findChildFieldConfigWithName(config.childPrototypeConfig, name);
    }
    return null;
  }

  addChild() {
    const newChild = this.childPrototypeConfig.elementType === DynamicFormElementType.GROUP ?
      DynamicFormGroup.create(this.childPrototypeConfig as DynamicFormGroupConfig, this.formArray) :
      DynamicFormField.create(this.childPrototypeConfig as DynamicFormFieldConfig, this.formArray)
    this.children.push(newChild);
  }

  removeChild(index: number) {
    this.formArray.removeAt(index);
    this.children.splice(index, 1);
  }

  clearAllChildren({ emitEvent } = { emitEvent: true }) {
    this.formArray.clear({ emitEvent });
    this.children = [];
  }

  override revert() {
    this.clearAllChildren({ emitEvent: false });
    this.children.push(...DynamicFormGeneratorUtils.generateChildren(this.initialChildren, this.formArray));
    this.formArray.updateValueAndValidity();
  }

  override hasError(): boolean {
    return this.formArray.touched && this.formArray.invalid;
  }

  override removeSelf() {
    super.removeSelf();
    DynamicFormGeneratorUtils.removeFormElementFromParent(this.name, this.parentForm as FormGroup);
    this.parentForm.updateValueAndValidity();
  }

  private configureInitialState(config: DynamicFormArrayConfig) {
    this.initialChildren = config.children ?? [];
    this.childPrototypeConfig = config.childPrototypeConfig;
  }

  private buildChildren(config: DynamicFormArrayConfig) {
    const preconfiguredChildren = DynamicFormGeneratorUtils.preconfigureChildren(config);
    this.children = DynamicFormGeneratorUtils.generateChildren(preconfiguredChildren, this.formArray);
  }

  private setupForm(config: DynamicFormArrayConfig, parentForm: FormGroup | FormArray) {
    this.formArray = new FormArray<any>([]);
    DynamicFormGeneratorUtils.addFormElementToParent(this.formArray, config.name, parentForm);
    const validators = this.validators.map(validator => new DynamicFormElementValidatorFactory().create(validator).validatorFn);
    this.formArray.addValidators(validators);
    this.formArray.updateValueAndValidity();
  }
}
