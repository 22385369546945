import { Component, Input } from '@angular/core';

@Component({
  selector: 'ellipse-dynamic-form-fields-plain-container',
  templateUrl: './dynamic-form-fields-plain-container.component.html',
  styleUrl: './dynamic-form-fields-plain-container.component.scss'
})
export class DynamicFormFieldsPlainContainerComponent {
  @Input() viewMode: 'form' | 'summary' = 'form';
}
