import { DynamicFormField } from '../dynamic-form-field.model';
import { DynamicFormFieldConfig } from '../dynamic-form-field-config.model';
import { map, Observable, of, startWith, takeUntil, tap, withLatestFrom } from 'rxjs';
import { DynamicFormFieldOption } from './dynamic-form-field-option.model';
import { FormArray, FormControl, FormGroup } from '@angular/forms';

export class DynamicFormFieldWithOptions extends DynamicFormField {

  public options!: DynamicFormFieldOption[];
  public options$!: Observable<DynamicFormFieldOption[]>;
  public parentCtrl?: FormControl;

  constructor(config: DynamicFormFieldConfig, parentForm: FormGroup | FormArray) {
    super(config, parentForm);
    this.options = config.options!;
    this.setParentControl(config, parentForm);
    this.options$ = this.buildOptionsObservable();
    this.retryNumberAsStringIfOptionsDoNotIncludeTheNumber();
  }

  private retryNumberAsStringIfOptionsDoNotIncludeTheNumber() {
    this.formControl.valueChanges.pipe(
      takeUntil(this.destroy$),
      withLatestFrom(this.options$),
      tap(([value, options]) => {
        if (Number.isInteger(value) && !options.find(option => option.value === value)) {
          this.formControl.patchValue(value.toString(), { emitEvent: false });
        }
      })
    ).subscribe();
  }

  private setParentControl(config: DynamicFormFieldConfig, parentForm: FormGroup | FormArray) {
    if (config.parentControlName) {
      this.parentCtrl = this.getParentControl(parentForm, config);
      this.clearControlWhenParentEmits(this.parentCtrl);
    }
  }

  private getParentControl(parentForm: FormGroup | FormArray, config: DynamicFormFieldConfig): FormControl {
    return parentForm.get(config.parentControlName!) as FormControl;
  }

  private connectOptionsObservableToParentControl(parentControl: FormControl) {
    return parentControl.valueChanges.pipe(
      startWith(parentControl.value),
      map(value => value ?
        this.options.filter(option => option.parentItemId === value) :
        this.options
      ),
    );
  }

  private clearControlWhenParentEmits(parentControl: FormControl<any>) {
    parentControl.valueChanges.pipe(
      takeUntil(this.destroy$),
      tap(() => this.clear())
    ).subscribe();
  }

  private buildFromStaticOptions() {
    return of(this.options);
  }

  private buildOptionsObservable() {
    return this.parentCtrl ?
      this.connectOptionsObservableToParentControl(this.parentCtrl) :
      this.buildFromStaticOptions();
  }
}
