<div class="field-container" [ngClass]="widthClass">
  <ng-template ellipseFieldHost></ng-template>
</div>

<ellipse-icon [matTooltip]="dynamicFormField.tooltip!"
              #tooltip="matTooltip"
              (click)="tooltip.toggle()"
              class="tooltip-button"
              *ngIf="showTooltip && viewMode === 'form'"
              iconName="fa-regular fa-circle-info"
              iconSize="xl"
              matTooltipPosition="left"
              [matTooltipClass]="matTooltipClass">
</ellipse-icon>

