import { ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  BaseDynamicFieldWithOptionsComponent
} from '../base-dynamic-field-with-options/base-dynamic-field-with-options.component';
import { filter, map, Observable, startWith, Subject, switchMap, take, takeUntil, tap } from 'rxjs';
import { DynamicFormFieldOption } from '../../../../models';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'ellipse-dynamic-autocomplete-field',
  templateUrl: './dynamic-autocomplete-field.component.html',
  styleUrls: ['./dynamic-autocomplete-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicAutocompleteFieldComponent extends BaseDynamicFieldWithOptionsComponent implements OnInit {

  @ViewChild('textInput') textInput!: ElementRef<HTMLInputElement>;

  intermediateFormCtrl!: FormControl;
  filteredOptions$!: Observable<DynamicFormFieldOption[]>;
  private controlBlurred$ = new Subject<void>();

  private changeOrFocusInput$ = new Subject<string>();

  override ngOnInit() {
    super.ngOnInit();
    this.createIntermediateFormCtrl();
    this.createFilteredOptions();
    this.disableInputIfFieldReadonly();
    this.checkValidityOnBlur();
    this.listenForPatchEvents();
  }

  onClearControl(event: MouseEvent) {
    event.stopPropagation();
    this.dynamicFormField.clear();
  }

  displayCentreName(option: DynamicFormFieldOption): string {
    return option?.label || '';
  }

  onInput() {
    this.changeOrFocusInput$.next(this.textInput.nativeElement.value);
  }

  onFocus() {
    this.changeOrFocusInput$.next(this.textInput.nativeElement.value);
  }

  onBlur() {
    this.controlBlurred$.next();
  }

  private disableInputIfFieldReadonly() {
    if (this.dynamicFormField.readonly) {
      this.dynamicFormField.formControl.disable();
      this.intermediateFormCtrl.disable();
    }
  }

  private createFilteredOptions() {
    this.filteredOptions$ = this.changeOrFocusInput$.pipe(
      startWith(''),
      switchMap(value => this.dynamicFormField.options$.pipe(
        map(options => this.filterOptionsByValue(options, value))
      )
    ));
  }

  private filterOptionsByValue(options: DynamicFormFieldOption[], value: string) {
    if (value.length < 3) {
      return [];
    }
    const words = value.split(' ');
    return options
      .filter(option => words
        .every(word => option.label.toLocaleLowerCase().includes(word.toLocaleLowerCase()))
      );
  }

  private checkValidityOnBlur() {
    this.controlBlurred$.pipe(
      takeUntil(this.destroy$),
      switchMap(() => this.dynamicFormField.options$),
      filter(options => this.textInput.nativeElement.value !== '' && this.noOptionWithThisLabel(options)),
      tap(() => this.intermediateFormCtrl.patchValue(null))
    ).subscribe();
  }

  private noOptionWithThisLabel(options: DynamicFormFieldOption[]) {
    return !options.some(option => option.label === this.textInput.nativeElement.value);
  }

  private createIntermediateFormCtrl() {
    this.intermediateFormCtrl = new FormControl(null);
    this.intermediateFormCtrl.valueChanges.pipe(
      takeUntil(this.destroy$),
      tap((option: DynamicFormFieldOption) => this.dynamicFormField.formControl.patchValue(option?.value ?? null))
    ).subscribe();
  }

  private listenForPatchEvents() {
    this.dynamicFormField.formControl.valueChanges.pipe(
      takeUntil(this.destroy$),
      startWith(this.dynamicFormField.formControl.value),
      switchMap((id: number) => this.getOptionById$(id)),
      tap(option => this.intermediateFormCtrl.patchValue(option))
    ).subscribe();
  }

  private getOptionById$(id: number): Observable<DynamicFormFieldOption> {
    return this.filteredOptions$.pipe(
      map(options => options.find(option => option.value === id)!),
      take(1)
    );
  }
}
