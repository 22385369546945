import { Component, OnInit } from '@angular/core';
import { BaseDynamicFieldComponent } from '../base-dynamic-field/base-dynamic-field.component';

@Component({
  selector: 'ellipse-dynamic-checkbox-field',
  templateUrl: './dynamic-checkbox-field.component.html',
  styleUrls: ['./dynamic-checkbox-field.component.scss']
})
export class DynamicCheckboxFieldComponent extends BaseDynamicFieldComponent implements OnInit {

}
