import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from '@anxious-unicorn/env/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class BadgeService {

  constructor(private http: HttpClient) {}

  getPendingAccessRequestsCount(): Observable<{ count: number }> {
    return this.http.get<{ count: number }>(`${environment.baseUrl}/access-requests/count`);
  }

  getNewResearchProjectsCount(): Observable<{ count: number }> {
    return this.http.get<{ pendingCount: number }>(`${environment.baseUrl}/projets-recherche/pending-count`).pipe(
      map(({ pendingCount }) => ({ count: pendingCount }))
    );
  }
}
