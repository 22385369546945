import { DynamicFormFieldConfig } from '../dynamic-form-field-config.model';
import { FormArray, FormGroup } from '@angular/forms';
import { DynamicCheckboxFieldComponent } from '../../../components/dynamic-form/dynamic-form-field';
import { DynamicFormField } from '../dynamic-form-field.model';

export class CheckboxField extends DynamicFormField {
  constructor(config: DynamicFormFieldConfig, parentForm: FormGroup | FormArray) {
    super(config, parentForm);
    this.component = DynamicCheckboxFieldComponent;
  }

  static override create(config: DynamicFormFieldConfig, parentForm: FormGroup | FormArray) {
    return new CheckboxField(config, parentForm);
  }
}
