import { DynamicSelectionListFieldComponent } from '../../../components/dynamic-form/dynamic-form-field';
import { DynamicFormFieldConfig } from '../dynamic-form-field-config.model';
import { FormArray, FormGroup } from '@angular/forms';
import { DynamicFormFieldWithOptions } from '../dynamic-form-field-with-options';

export class SelectionListField extends DynamicFormFieldWithOptions {
  constructor(config: DynamicFormFieldConfig, parentForm: FormGroup | FormArray) {
    super(config, parentForm);
    this.component = DynamicSelectionListFieldComponent;
  }

  static override create(config: DynamicFormFieldConfig, parentForm: FormGroup | FormArray) {
    return new SelectionListField(config, parentForm);
  }
}
