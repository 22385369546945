<mat-form-field *ngIf="viewMode === 'form'">
  <mat-label>{{ dynamicFormField.label }}</mat-label>
  <input type="text"
         #textInput
         [attr.maxlength]="maxLength"
         matInput
         [formControl]="dynamicFormField.formControl"
         [readonly]="dynamicFormField.readonly">
  <mat-error *ngIf="errorMsg$ | async as errorMsg">{{ errorMsg }}</mat-error>
</mat-form-field>
<ellipse-dynamic-summary-field *ngIf="viewMode === 'summary'"
                               [label]="dynamicFormField.label"
                               [value]="dynamicFormField.formControl.value"/>
