import { ValidatorFn } from '@angular/forms';
import {
  NoDuplicateValueCombinationsError
} from '../../dynamic-form-array/errors/no-duplicate-value-combinations.error';

export const noDuplicateValueCombinations = (controlCombinations: string): ValidatorFn => {
  return (control: any): { noDuplicateValueCombinations: NoDuplicateValueCombinationsError } | null => {
    return null;
  };
}
