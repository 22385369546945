import { DynamicFormFieldValidator } from '../dynamic-form-element-validator.model';
import { DynamicFormElementValidatorConfig } from '../dynamic-form-element-validator-config.model';
import { DynamicFormElementValidatorType } from '../dynamic-form-element-validator-type.enum';
import { maxDate } from '../custom-validator-functions';
import { DateUtils } from '../../../utils/date.utils';

export class MaxDateValidator extends DynamicFormFieldValidator {

  defaultErrorMsg = 'La date ne doit pas être après le {{value}}';

  constructor(config: DynamicFormElementValidatorConfig) {
    super(config);
    this.setType(DynamicFormElementValidatorType.MAX_DATE);
    this.setValue(DateUtils.getDateFormatString(config.value));
    this.setDisplayValue(DateUtils.getDisplayString(config.value));
    this.configureError(config);
    this.setValidatorFn(maxDate(this.value));
  }

  configureError(config: DynamicFormElementValidatorConfig) {
    this.errorMsg = config.errorMsg ?? this.defaultErrorMsg;
    this.interpolateValueInErrorMessage(this.displayValue);
    this.errorKey = 'maxdate';
  }

}
