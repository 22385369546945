import { DynamicFormFieldWithOptions } from '../dynamic-form-field-with-options';
import { DynamicFormFieldConfig } from '../dynamic-form-field-config.model';
import { FormArray, FormGroup } from '@angular/forms';
import { DynamicDropdownFieldComponent } from '../../../components/dynamic-form/dynamic-form-field';

export class DropdownField extends DynamicFormFieldWithOptions {
  constructor(config: DynamicFormFieldConfig, parentForm: FormGroup | FormArray) {
    super(config, parentForm);
    this.component = DynamicDropdownFieldComponent;
  }

  static override create(config: DynamicFormFieldConfig, parentForm: FormGroup | FormArray) {
    return new DropdownField(config, parentForm);
  }
}
