import { DynamicFormLinkType } from './dynamic-form-link-type.enum';
import { ExistsForValueLink } from './link-types/exists-for-value-link.model';

export class DynamicFormLink {
  linkType!: DynamicFormLinkType;
  values!: (string | number | boolean)[];

  static isExistsForValueLink(link: DynamicFormLink): link is ExistsForValueLink {
    return link.linkType === DynamicFormLinkType.EXISTS_FOR_VALUES;
  }
}
