<mat-card *ngIf="viewMode === 'form'">
  <mat-card-header>
    <mat-card-title>{{ dynamicFormField.label }}</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <mat-selection-list [formControl]="dynamicFormField.formControl"
                        *ngIf="dynamicFormField.options$ | async as options"
                        [multiple]="dynamicFormField.multiSelect">
      <mat-list-option *ngFor="let option of options" [value]="option.value">{{ option.label }}</mat-list-option>
    </mat-selection-list>
  </mat-card-content>
</mat-card>
<ellipse-dynamic-summary-field *ngIf="viewMode === 'summary'"
                               [label]="dynamicFormField.label"
                               [value]="summaryFormattedValue"/>
