export enum DynamicFormElementValidatorType {
  REQUIRED = 'REQUIRED',
  MIN_VALUE = 'MIN_VALUE',
  MAX_VALUE = 'MAX_VALUE',
  MIN_LENGTH = 'MIN_LENGTH',
  MAX_LENGTH = 'MAX_LENGTH',
  MIN_DATE = 'MIN_DATE',
  MAX_DATE = 'MAX_DATE',
  MIN_INTERVAL_BEFORE_TODAY = 'MIN_INTERVAL_BEFORE_TODAY',
  MAX_INTERVAL_BEFORE_TODAY = 'MAX_INTERVAL_BEFORE_TODAY',
  MIN_INTERVAL_AFTER_TODAY = 'MIN_INTERVAL_AFTER_TODAY',
  MAX_INTERVAL_AFTER_TODAY = 'MAX_INTERVAL_AFTER_TODAY',
  NO_DUPLICATE_VALUES_FOR_CONTROL = 'NO_DUPLICATE_VALUES_FOR_CONTROL',
  NO_DUPLICATE_VALUE_COMBINATIONS_FOR_CONTROLS = 'NO_DUPLICATE_VALUE_COMBINATIONS_FOR_CONTROLS'
}
