import { DynamicFormFieldValidator } from '../dynamic-form-element-validator.model';
import { DynamicFormElementValidatorConfig } from '../dynamic-form-element-validator-config.model';
import { DynamicFormElementValidatorType } from '../dynamic-form-element-validator-type.enum';
import { noDuplicateValueCombinations } from '../custom-validator-functions/no-duplicate-value-combinations.validator';

export class NoDuplicateValueCombinationsValidator extends DynamicFormFieldValidator {
  defaultErrorMsg = 'La combinaison de valeurs pour les champs ne peut être répétée.';
  constructor(config: DynamicFormElementValidatorConfig) {
    super(config);
    this.setType(DynamicFormElementValidatorType.NO_DUPLICATE_VALUE_COMBINATIONS_FOR_CONTROLS);
    this.setValue(config.value);
    this.configureError(config);
    this.setValidatorFn(noDuplicateValueCombinations(this.value));
  }

  configureError(config: DynamicFormElementValidatorConfig) {
    this.errorMsg = config.errorMsg ?? this.defaultErrorMsg;
    this.interpolateValueInErrorMessage(this.displayValue);
    this.errorKey = 'noDuplicateValueCombinations';
  }
}
