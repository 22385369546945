import { DynamicFormField } from '../dynamic-form-field.model';
import { FormArray, FormGroup } from '@angular/forms';
import { DynamicFormFieldConfig } from '../dynamic-form-field-config.model';
import { DynamicCalculatorFieldComponent } from '../../../components/dynamic-form/dynamic-form-field/dynamic-calculator-field/dynamic-calculator-field.component';

export class CalculatorField extends DynamicFormField {
  constructor(config: DynamicFormFieldConfig, parentForm: FormGroup | FormArray) {
    super(config, parentForm);
    this.component = DynamicCalculatorFieldComponent
  }

  static override create(config: DynamicFormFieldConfig, parentForm: FormGroup | FormArray) {
    return new CalculatorField(config, parentForm);
  }
}
