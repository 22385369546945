import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as CoreActions from '@anxious-unicorn/core/state/core.actions';
import { AuthFacade } from 'auth-unicorn';
import { filter, take, tap } from 'rxjs';

@Component({
  selector: 'bna-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(private auth: AuthFacade,
              private store: Store) {}

  ngOnInit(): void {
    this.initAppDataWhenUserAuthenticated();
  }

  private initAppDataWhenUserAuthenticated() {
    this.auth.currentUser().pipe(
      filter(user => user.id !== ''),
      take(1),
      tap(() => {
        this.store.dispatch(CoreActions.InitializeApp());
      })
    ).subscribe();
  }
}
