import { DynamicFormFieldConfig } from './dynamic-form-field-config.model';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { DynamicFormGeneratorUtils } from '../../utils/dynamic-form-generator.utils';
import { DynamicFormFieldType } from './dynamic-form-field-type.enum';
import { DynamicFormElement } from '../dynamic-form-element';
import { DynamicFormFieldValidator } from '../dynamic-form-validators';
import { DynamicFormElementValidatorFactory } from '../dynamic-form-validators/dynamic-form-element-validator.factory';

export class DynamicFormField extends DynamicFormElement {
  public formControl!: FormControl;
  public fieldType!: DynamicFormFieldType;
  public component!: any;

  public validatorErrorMessageMap: { [errorType: string]: string } = {};
  public showOptionTooltips?: boolean;
  public initialValue?: string | number | boolean;
  public defaultValue?: string | number | boolean;
  public numbersOnly?: boolean;
  public multiSelect: boolean = false;
  public showDatepicker: boolean = false;

  public configuredValidators: DynamicFormFieldValidator[] = [];

  constructor(config: DynamicFormFieldConfig, public parentForm: FormGroup | FormArray) {
    super(config);
    this.setupForm(config, parentForm);
    this.setInitialState(config);
    this.setInitialAndDefaultValues(config);
    this.configureValidators(config);
    this.interpolateValidationValuesIntoTooltip();
    this.setDisabledState(config);
  }

  static create(config: DynamicFormFieldConfig, parentForm: FormGroup | FormArray): DynamicFormField {
    return new DynamicFormField(config, parentForm);
  }

  clear() {
    this.defaultValue ?
      this.formControl.setValue(this.defaultValue) :
      this.formControl.reset();
  }

  override revert() {
    this.formControl.setValue(this.initialValue);
  }

  addConfigValidators(validators: DynamicFormFieldValidator[]) {
    this.formControl.addValidators(validators.map(validator => {
      this.addValidatorToErrorMessageMap(validator);
      return validator.validatorFn;
    }));
    this.formControl.updateValueAndValidity();
  }

  private addValidatorToErrorMessageMap(validator: DynamicFormFieldValidator) {
    this.validatorErrorMessageMap[validator.errorKey] = validator.errorMsg;
  }

  override hasError(): boolean {
    return this.formControl.touched && this.formControl.invalid;
  }

  override removeSelf() {
    super.removeSelf();
    DynamicFormGeneratorUtils.removeFormElementFromParent(this.name, this.parentForm as FormGroup);
    this.parentForm.updateValueAndValidity();
  }

  override addSelf() {
    super.addSelf();
    DynamicFormGeneratorUtils.addFormElementToParent(this.formControl, this.name, this.parentForm);
    this.revert();
    this.formControl.markAsUntouched();
    this.parentForm.updateValueAndValidity();
  }

  private configureValidators(config: DynamicFormFieldConfig) {
    if (config.validators) {
      const factory = new DynamicFormElementValidatorFactory();
      this.configuredValidators = config.validators.map(validatorConfig => factory.create(validatorConfig));
      this.addConfigValidators(this.configuredValidators);
    }
  }

  private setDisabledState(config: DynamicFormFieldConfig) {
    if (config.disabled || config.readonly) {
      this.formControl.disable();
    }
  }

  private setInitialState(config: DynamicFormFieldConfig) {
    this.fieldType = config.fieldType;
    this.showOptionTooltips = config.showOptionTooltips;
    this.numbersOnly = !!config.numbersOnly;
    this.multiSelect = !!config.multiSelect;
    this.showDatepicker = !!config.showDatepicker;
  }

  private setupForm(config: DynamicFormFieldConfig, parentForm: FormGroup | FormArray) {
    this.formControl = new FormControl(config.value);
    DynamicFormGeneratorUtils.addFormElementToParent(this.formControl, config.name, parentForm);
  }

  private setInitialAndDefaultValues(config: DynamicFormFieldConfig) {
    if (config.value) {
      this.initialValue = config.value;
    }

    if (config.defaultValue) {
      this.defaultValue = config.defaultValue;
    }
  }

  private interpolateValidationValuesIntoTooltip() {
    let modifiedTooltip = this.tooltip;
    this.configuredValidators.forEach(validator => {
      modifiedTooltip = modifiedTooltip?.replace(`{{${validator.errorKey}}}`, validator.displayValue ?? validator.value);
    });
    this.tooltip = modifiedTooltip;
  }
}
