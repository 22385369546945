import { DynamicFormElementValidatorType } from './dynamic-form-element-validator-type.enum';
import { ValidatorFn } from '@angular/forms';
import { DynamicFormElementValidatorConfig } from './dynamic-form-element-validator-config.model';

export class DynamicFormFieldValidator {

  type!: DynamicFormElementValidatorType;
  errorMsg!: string;
  validatorFn!: ValidatorFn;
  errorKey!: string;
  value?: any;
  displayValue?: string;

  constructor(_: DynamicFormElementValidatorConfig) {}

  setType(type: DynamicFormElementValidatorType) {
    this.type = type;
  }

  setValidatorFn(validatorFn: ValidatorFn) {
    this.validatorFn = validatorFn;
  }

  setValue(value: any) {
    this.value = value;
  }

  setDisplayValue(value: string) {
    this.displayValue = value;
  }

  interpolateValueInErrorMessage(value: any) {
    this.errorMsg = this.errorMsg.replace('{{value}}', value);
  }

}
