<div class="container" *ngIf="viewMode === 'form'">
  <mat-form-field>
    <ellipse-icon *ngIf="!required && !dynamicFormField.readonly"
                  class="clear-icon"
                  matSuffix
                  (click)="onClearControl($event)"
                  iconName="fa-regular fa-xmark"
                  iconSize="lg" />
    <mat-label>{{ dynamicFormField.label }}</mat-label>
    <input matInput
           #textInput
           type="text"
           (input)="onInput()"
           (focus)="onFocus()"
           (blur)="onBlur()"
           [matAutocomplete]="autocomplete"
           [formControl]="intermediateFormCtrl">
    <mat-autocomplete #autocomplete="matAutocomplete"
                      [displayWith]="displayCentreName"
                      requireSelection>
      <mat-option *ngFor="let option of filteredOptions$ | async"
                  [value]="option"
                  [disabled]="!!option.disabled">
        {{ option.label }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <ellipse-icon *ngIf="dynamicFormField.showOptionTooltips"
                (click)="onShowOptionTooltips()"
                iconName="fa-regular fa-circle-info"
                iconSize="xl" />
</div>
<ellipse-dynamic-summary-field *ngIf="viewMode === 'summary'"
                               [label]="dynamicFormField.label"
                               [value]="dynamicFormField.formControl.value"/>
