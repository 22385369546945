import dayjs from 'dayjs';
import Duration from 'dayjs/plugin/duration';

export class DateUtils {
  static isBefore(dateToCheck: string, dateToCheckAgainst: string): boolean {
    return dayjs(dateToCheck).isBefore(dateToCheckAgainst);
  }

  static isAfter(dateToCheck: string, dateToCheckAgainst: string): boolean {
    return dayjs(dateToCheck).isAfter(dateToCheckAgainst);
  }

  static getDisplayString(dateString: string): string {
    return dayjs(dateString).format('DD/MM/YYYY');
  }

  static getDateFormatString(dateString: string): string {
    return dayjs(dateString).format('YYYY-MM-DD');
  }

  static intervalStringToDurationObject(intervalString: string) {
    const periodDictionary: { [key: string]: 'years' | 'months' | 'weeks' | 'days' } = {
      Y: 'years',
      M: 'months',
      W: 'weeks',
      D: 'days'
    };
    const intervalElements = intervalString.split('-');
    return intervalElements.reduce((acc, curr) => {
      let abbrieviatedPeriod = curr.charAt(curr.length - 1).toUpperCase();
      return {
        ...acc,
        [periodDictionary[abbrieviatedPeriod]]: parseInt(curr.substring(0, curr.length - 1))
      };
    }, {});
  }

  static todayMinusDuration(duration: {}): string {
    dayjs.extend(Duration);
    const date = dayjs().subtract(dayjs.duration(duration))
    return date.format('YYYY-MM-DD');
  }

  static todayPlusDuration(duration: {}): string {
    dayjs.extend(Duration);
    return dayjs().add(dayjs.duration(duration)).format('YYYY-MM-DD');
  }
}
