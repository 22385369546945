import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { List } from 'types-unicorn';
import { HttpClient } from '@angular/common/http';
import { environment } from '@admin-unicorn/env/environment';

@Injectable({
  providedIn: 'root'
})
export class ListsService {

  constructor(private http: HttpClient) {}

  getListsByName(names: string[]): Observable<List[]> {
    return this.http.get<List[]>(`${environment.apiBaseUrl}/lists/${names.join(',')}`);
  };

}
