import { createAction, props } from '@ngrx/store';
import { BnaError } from 'types-unicorn';

export const InitializeApp = createAction(
  '[AppComponent] Initialize App'
);

export const GetPendingAccessRequestsCount = createAction(
  '[@Effect initPendingAccessRequestsCount$] Get Pending Access Requests Count'
);

export const GetPendingAccessRequestCountFromAccessRequestsFeature = createAction(
  '[@Effect getPendingRequestsCountAfterGettingRequests$] Get Pending Access Request Count From Access Requests Feature'
);

export const GetPendingAccessRequestsCountSuccess = createAction(
  '[@Effect getAccessRequestsCount$] Get Access Requests Count Success',
  props<{ count: number }>()
);

export const GetPendingAccessRequestsCountFailure = createAction(
  '[@Effect getAccessRequestsCount$] Get Access Requests Count Failure',
  props<{ error: BnaError }>()
);

export const GetNewResearchProjectsCount = createAction(
  '[@Effect initNewResearchProjectsCount$] Get New Research Projects Count'
);

export const GetNewResearchProjectsCountFromResearchProjectsFeature = createAction(
  '[@Effect getNewResearchProjectsCountFromResearchProjectsFeature$] Get New Research Projects Count From Research Projects Feature'
);

export const GetNewResearchProjectsCountSuccess = createAction(
  '[@Effect getNewResearchProjectsCount$] Get New Research Projects Count Success',
  props<{ count: number }>()
);

export const GetNewResearchProjectsCountFailure = createAction(
  '[@Effect getNewResearchProjectsCount$] Get New Research Projects Count Failure',
  props<{ error: BnaError }>()
);
