import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  BaseDynamicFieldWithOptionsComponent
} from '../base-dynamic-field-with-options/base-dynamic-field-with-options.component';

@Component({
  selector: 'ellipse-dynamic-radio-field',
  templateUrl: './dynamic-radio-field.component.html',
  styleUrls: ['./dynamic-radio-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicRadioFieldComponent extends BaseDynamicFieldWithOptionsComponent {


}
