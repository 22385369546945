import {
  DynamicFormArray, DynamicFormArrayConfig,
  DynamicFormElement, DynamicFormElementConfig,
  DynamicFormField,
  DynamicFormFieldConfig,
  DynamicFormGroup, DynamicFormGroupConfig
} from '../models';
import { FormGroup } from '@angular/forms';

export class DynamicFormUtils {
  static isDynamicFormArray(element: DynamicFormElement): element is DynamicFormArray {
    return element instanceof DynamicFormArray;
  }

  static isDynamicFormGroup(element: DynamicFormElement): element is DynamicFormGroup {
    return element instanceof DynamicFormGroup;
  }

  static isDynamicFormField(element: DynamicFormElement): element is DynamicFormField {
    return element instanceof DynamicFormField;
  }

  static getInterpolatedWordsFromText(text: string): string[] {
    const descriptionDynamicElements = text.match(/\{\{(.*?)}}/g) || [];
    const words = descriptionDynamicElements.map(el => el.substring(2, el.length - 2));
    return words;
  }

  static replaceInterpolatedTextWithFormFieldValues(text: string, formGroup: FormGroup): string {
    const descriptionDynamicElements = text.match(/\{\{(.*?)}}/g) || [];
    const formControlValues = descriptionDynamicElements.map(el => formGroup.get(el.substring(2, el.length - 2))!.value);
    descriptionDynamicElements.forEach((el, index) => text = text?.replace(el, formControlValues[index]));
    return text;
  }

  static clear(elements: DynamicFormElement[], emitEvent = true) {
    elements.forEach(element => {
      if (DynamicFormUtils.isDynamicFormArray(element)) {
        element.clearAllChildren({ emitEvent });
      } else if (DynamicFormUtils.isDynamicFormGroup(element)) {
        element.clearAllChildren()
      } else if (DynamicFormUtils.isDynamicFormField(element)) {
        element.clear();
      }
    });
  }

  static isDynamicFormFieldConfig(config: DynamicFormElementConfig): config is DynamicFormFieldConfig {
    return config.hasOwnProperty('fieldType');
  }

  static isDynamicFormGroupConfig(config: DynamicFormElementConfig): config is DynamicFormGroupConfig {
    return config.hasOwnProperty('children') && !config.hasOwnProperty('childPrototypeConfig');
  }

  static isDynamicFormArrayConfig(config: DynamicFormElementConfig): config is DynamicFormArrayConfig {
    return config.hasOwnProperty('childPrototypeConfig');
  }
}
