import { DynamicFormArray } from '../dynamic-form-array.model';

export abstract class DynamicFormArrayError {

  message: string;
  affectedControlNames: string[];
  affectedValues: any[];

  protected constructor(details: { affectedControlNames?: string[], affectedValues?: any[], message?: string }) {
    this.affectedControlNames = details.affectedControlNames || [];
    this.affectedValues = details.affectedValues || [];
    this.message = details.message || `Cette collection de champs contient une erreur.`;
  }

  abstract compileMessage(formArray: DynamicFormArray): string;
}
