import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@anxious-unicorn/shared/modules/material.module';
import { FormContainerComponent } from '@anxious-unicorn/shared/components';
import { EllipseDynamicFormModule } from 'dynamic-form';

@NgModule({
  declarations: [
    FormContainerComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    EllipseDynamicFormModule,
  ],
  exports: [
    ReactiveFormsModule,
    MaterialModule,
    FormContainerComponent,
  ]
})
export class SharedModule { }
