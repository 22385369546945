@if (dynamicFormElement.exists) {
  @if (templateIsExpansion) {
    <ellipse-dynamic-form-fields-expansion-container [dynamicFormElement]="dynamicFormElement">
      <ng-container *ngTemplateOutlet="formContent" formContent/>
      <ng-container *ngTemplateOutlet="formArrayActions" formArrayActions/>
    </ellipse-dynamic-form-fields-expansion-container>
  } @else if (templateIsCard) {
    <ellipse-dynamic-form-fields-card-container [dynamicFormElement]="dynamicFormElement">
      <ng-container *ngTemplateOutlet="formContent" formContent/>
      <ng-container *ngTemplateOutlet="formArrayActions" formArrayActions/>
      <ng-container *ngTemplateOutlet="arrayItemActions" arrayItemActions/>
    </ellipse-dynamic-form-fields-card-container>
  } @else if (templateIsPlain) {
    <ellipse-dynamic-form-fields-plain-container [viewMode]="viewMode">
      <ng-container *ngTemplateOutlet="formContent" formContent/>
      <ng-container *ngTemplateOutlet="arrayItemActions" arrayItemActions/>
    </ellipse-dynamic-form-fields-plain-container>
  }
}

<ng-template #formContent>
  <div class="panel-content">
    <ng-container *ngFor="let element of dynamicFormElement.children; let i = index">
      <ng-container *ngIf="element.exists">
        <ellipse-dynamic-form-field-container *ngIf="element.elementType === DynamicFormElementType.FIELD"
                                              [dynamicFormField]="getDynamicFormField(element)"
                                              [viewMode]="viewMode"/>
        <ellipse-dynamic-form-fields-container
          *ngIf="element.elementType === DynamicFormElementType.GROUP || element.elementType === DynamicFormElementType.ARRAY"
          [dynamicFormElement]="getDynamicFormFieldCollection(element)"
          [parentIsArray]="isArray"
          (elementDeleted)="onRemoveChild(i)"
          [viewMode]="viewMode"/>
        <div class="break" *ngIf="element.wholeRow"></div>
      </ng-container>
    </ng-container>
    @if (dynamicFormElement.errorMsg$ | async; as errorMsg) {
      <p class="error-text">{{ errorMsg }}</p>
    }
  </div>
</ng-template>

<ng-template #formArrayActions>
  <ng-container *ngIf="viewMode === 'form'">
    <ellipse-dynamic-form-array-actions *ngIf="isArray" (addChild)="onAddChild()" />
  </ng-container>
</ng-template>
<ng-template #arrayItemActions>
  @if (parentIsArray && viewMode === 'form') {
    <button mat-icon-button color="warn" (click)="onDeleteSelf()">
      <ellipse-icon iconName="fa fa-trash" iconSize="xs"/>
    </button>
  }
</ng-template>
