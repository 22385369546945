import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, Observable } from 'rxjs';
import * as UiSelectors from '../../state/ui/ui.selectors';
import * as UiActions from '../../state/ui/ui.actions';
import { MainNavMenuItem } from 'types-unicorn';
import { AuthFacade } from 'auth-unicorn';
import { Router } from '@angular/router';

@Component({
  selector: 'bna-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayoutComponent implements OnInit {

  menuOpen$!: Observable<boolean>;
  menuItems$!: Observable<MainNavMenuItem[]>;
  currentUserFullName$!: Observable<string>;

  constructor(private store: Store,
              private auth: AuthFacade,
              private router: Router) {}

  ngOnInit(): void {
    this.connectStoreObservables();
  }

  onToggleMenu(): void {
    this.store.dispatch(UiActions.ToggleSideMenu());
  }

  private connectStoreObservables(): void {
    this.menuOpen$ = this.store.select(UiSelectors.selectMenuOpen);
    this.menuItems$ = this.store.select(UiSelectors.selectMainNavMenuItems).pipe(
      filter(items => items.length > 0)
    );
    this.currentUserFullName$ = this.auth.currentUserFullName();
  }

  onLogout() {
    this.auth.logout();
  }

  onChangePassword() {
    this.auth.changePassword();
  }

  onShowProfile() {
    this.router.navigate(['my-profile']);
  }
}
