import { NgModule } from '@angular/core';
import { NgrxModule } from '@anxious-unicorn/core/modules/ngrx.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { LayoutComponent } from './components/layout/layout.component';
import { SharedModule } from '@anxious-unicorn/shared/shared.module';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { EllipseToolbarModule } from 'ui-components/toolbar';
import { EllipseMainNavMenuModule } from 'ui-components/main-nav-menu';
import { AuthUnicornModule } from 'auth-unicorn';
import { environment } from '@anxious-unicorn/env/environment';
import { InterceptorsModule } from '@anxious-unicorn/core/modules/interceptors.module';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

@NgModule({
  imports: [
    CommonModule,
    NgrxModule,
    HttpClientModule,
    SharedModule,
    RouterModule,
    EllipseToolbarModule,
    EllipseMainNavMenuModule,
    AuthUnicornModule.forRoot({
      baseUrl: environment.baseUrl,
      refreshTokenLifespan: 900,
      publicBaseUrl: environment.publicBaseUrl
    }),
    InterceptorsModule, // must be imported AFTER AuthUnicornModule for interceptor order to be correct
  ],
  exports: [
    BrowserAnimationsModule,
    LayoutComponent
  ],
  declarations: [
    LayoutComponent
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        subscriptSizing: 'dynamic'
      }
    }
  ]
})
export class CoreModule { }
