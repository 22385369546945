import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BaseDynamicFieldComponent } from '../base-dynamic-field/base-dynamic-field.component';
import { DynamicFormElementValidatorType } from '../../../../models/dynamic-form-validators';
import { filter, fromEvent, map, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'ellipse-dynamic-text-field',
  templateUrl: './dynamic-text-field.component.html',
  styleUrls: ['./dynamic-text-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicTextFieldComponent extends BaseDynamicFieldComponent implements OnInit, AfterViewInit {
  maxLength?: number;

  @ViewChild('textInput') textInput!: ElementRef;

  override ngOnInit() {
    super.ngOnInit();
    if (this.viewMode === 'form') {
      this.configureMaxLengthValidation();
    }
  }

  ngAfterViewInit() {
    if (this.viewMode === 'form') {
      this.configureNumbersOnlyValidation();
    }
  }

  private configureMaxLengthValidation() {
    const maxLengthValidator = this.dynamicFormField.validators?.find(validator => validator.type === DynamicFormElementValidatorType.MAX_LENGTH);
    if (maxLengthValidator) {
      this.maxLength = maxLengthValidator.value;
    }
  }

  private configureNumbersOnlyValidation() {
    if (this.dynamicFormField.numbersOnly) {
      fromEvent(this.textInput.nativeElement, 'keydown').pipe(
        takeUntil(this.destroy$),
        map(event => event as KeyboardEvent),
        filter((event: KeyboardEvent) => !this.isAcceptedKeyForNumbersOnlyField(event.key)),
        tap(event => event.preventDefault()),
      ).subscribe();
    }
  }

  private isAcceptedKeyForNumbersOnlyField(key: string): boolean {
    const isNumberKey = Number.isInteger(parseInt(key));
    const allowedNonNumberKeys = ['Tab', 'Shift', 'Backspace', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Meta', 'Alt', 'Enter', 'Return'];
    return isNumberKey || allowedNonNumberKeys.includes(key);
  }
}
