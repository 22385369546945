import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, ChildAuthGuard, PermissionsGuard } from 'auth-unicorn';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [ChildAuthGuard],
    children: [
      { path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule) },
      {
        path: 'patients',
        canActivate: [PermissionsGuard(['bnaPatientsCRU'])],
        loadChildren: () => import('./patients/patients.module').then(m => m.PatientsModule)
      },
      {
        path: 'my-centre',
        canActivate: [PermissionsGuard(['bnaCentreDataRU'])],
        loadChildren: () => import('./my-centre/my-centre.module').then(m => m.MyCentreModule)
      },
      {
        path: 'research-projects',
        canActivate: [PermissionsGuard(['bnaProjetRechercheCR', 'bnaProjetRechercheCRU'])],
        loadChildren: () => import('./research-projects/research-projects.module').then(m => m.ResearchProjectsModule)
      },
      {
        path: 'data-extraction',
        canActivate: [PermissionsGuard(['bnaRapportExport', 'bnaRapportView'])],
        loadChildren: () => import('./data-extraction/data-extraction.module').then(m => m.DataExtractionModule)
      },
      {
        path: 'my-profile',
        loadChildren: () => import('auth-unicorn').then(m => m.MyProfileModule)
      },
      {
        path: 'access-requests',
        loadChildren: () => import('./access-requests/access-requests.module').then(m => m.AccessRequestsModule)
      },
      {
        path: 'users',
        loadChildren: () => import('./users/users.module').then(m => m.UsersModule)
      },
      { path: '**', redirectTo: 'dashboard' }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
