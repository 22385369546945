import { Component } from '@angular/core';
import { BaseDynamicFieldComponent } from '../base-dynamic-field/base-dynamic-field.component';
import { MatDialog } from '@angular/material/dialog';
import { CalculatorModalComponent } from './calculator-modal/calculator-modal.component';
import { filter, tap } from 'rxjs';

@Component({
  selector: 'ellipse-dynamic-calculator-field',
  templateUrl: './dynamic-calculator-field.component.html',
  styleUrls: ['./dynamic-calculator-field.component.scss']
})
export class DynamicCalculatorFieldComponent extends BaseDynamicFieldComponent {

  constructor(private dialog: MatDialog) {
    super();
  }

  onOpenCalculatorModal() {
    this.openCalculatorDialog().afterClosed().pipe(
      filter(value => !!value), // when user closes dialog with cancel button, no value will be returned
      tap(this.patchValuesFromDialogToForm())
    ).subscribe();
  }

  private openCalculatorDialog() {
    return this.dialog.open(CalculatorModalComponent, this.getValuesFromForm());
  }

  private patchValuesFromDialogToForm() {
    return (value: { fields: { [label: string]: number }, checked: string[] }) => {
      const newFormControlValue = {
        fields: value.fields,
        checked: value.checked,
        score: this.calculateScoreFromModalValues(value)
      };
      this.dynamicFormField.formControl.patchValue(newFormControlValue);
    };
  }

  private getValuesFromForm() {
    return {
      data: {
        fields: this.dynamicFormField.formControl.value.fields,
        checked: this.dynamicFormField.formControl.value.checked,
      }
    };
  }

  private calculateScoreFromModalValues(value: { fields: { [p: string]: number }; checked: string[] }) {
    return value.checked.reduce((acc, curr) => acc + value.fields[curr], 0);
  }
}
