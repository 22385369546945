import { ChangeDetectionStrategy, Component, HostBinding, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { DynamicFormField } from '../../../models';
import { FieldHostDirective } from '../../../directives/field-host.directive';
import { BaseDynamicFieldComponent } from './base-dynamic-field/base-dynamic-field.component';
import {
  BaseDynamicFieldWithOptionsComponent
} from './base-dynamic-field-with-options/base-dynamic-field-with-options.component';
import { MatTooltip } from '@angular/material/tooltip';
import { ViewMode } from '../../../models/view-mode.type';

@Component({
  selector: 'ellipse-dynamic-form-field-container',
  templateUrl: './dynamic-form-field-container.component.html',
  styleUrls: ['./dynamic-form-field-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicFormFieldContainerComponent implements OnInit, OnDestroy {

  @Input() dynamicFormField!: DynamicFormField;
  @Input() parentElement!: FormGroup | FormArray;
  @Input() viewMode: ViewMode = 'form';

  @ViewChild('tooltip') tooltip!: MatTooltip;
  @ViewChild(FieldHostDirective, { static: true }) fieldHost!: FieldHostDirective;

  @HostBinding('class') widthClass!: string

  showTooltip!: boolean;
  matTooltipClass = { 'form-tooltip': true };

  ngOnInit() {
    this.buildFieldComponent();
    this.configureTooltip();
    this.removeSelfIfNecessaryInSummaryMode();
    this.setWidthClass();
  }

  private removeSelfIfNecessaryInSummaryMode() {
    if (this.summaryModeActive() && this.shouldBeHiddenInSummaryMode()) {
      this.dynamicFormField.removeSelf();
    }
  }

  private shouldBeHiddenInSummaryMode() {
    return this.dynamicFormField.hideInSummaryIfNull && !this.dynamicFormField.formControl.value;
  }

  private buildFieldComponent() {
    this.fieldHost.viewContainerRef.clear();
    const fieldRef = this.fieldHost.viewContainerRef.createComponent<BaseDynamicFieldComponent | BaseDynamicFieldWithOptionsComponent>(this.dynamicFormField.component);
    fieldRef.instance.dynamicFormField = this.dynamicFormField;
    fieldRef.instance.viewMode = this.viewMode;
  }

  ngOnDestroy() {
    this.dynamicFormField.destroy();
  }

  private configureTooltip() {
    this.showTooltip = !!this.dynamicFormField.tooltip && !this.dynamicFormField.showOptionTooltips
  }

  private setWidthClass() {
    this.widthClass = `width-${this.getWidth()}`;
  }

  private summaryModeActive() {
    return this.viewMode === 'summary';
  }

  private getWidth(): number {
    if (this.summaryModeActive()) {
      return 50;
    }
    return this.dynamicFormField.width ?? 100;
  }
}
