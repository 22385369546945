import { DynamicFormFieldConfig } from '../dynamic-form-field-config.model';
import { FormArray, FormGroup } from '@angular/forms';
import {
  DynamicRadioFieldComponent
} from '../../../components/dynamic-form/dynamic-form-field';
import { DynamicFormFieldWithOptions } from '../dynamic-form-field-with-options';

export class RadioField extends DynamicFormFieldWithOptions {
  constructor(config: DynamicFormFieldConfig, parentForm: FormGroup | FormArray) {
    super(config, parentForm);
    this.component = DynamicRadioFieldComponent;
  }

  static override create(config: DynamicFormFieldConfig, parentForm: FormGroup | FormArray) {
    return new RadioField(config, parentForm);
  }
}
