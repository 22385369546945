<ellipse-toolbar titleText="BNA"
                 (menuButtonClick)="onToggleMenu()"
                 [currentUserFullName]="currentUserFullName$ | async"
                 (logout)="onLogout()"
                 (changePassword)="onChangePassword()"
                 (showProfile)="onShowProfile()"/>
<mat-drawer-container>
  <mat-drawer *ngIf="menuItems$ | async as menuItems" mode="side" [opened]="menuOpen$ | async">
    <ellipse-main-nav-menu [menuItems]="menuItems"></ellipse-main-nav-menu>
  </mat-drawer>
  <mat-drawer-content>
    <div class="content">
      <router-outlet></router-outlet>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
