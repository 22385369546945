import {
  AutocompleteField,
  CalculatorField,
  CheckboxField,
  DateField,
  DropdownField,
  DynamicFormField,
  DynamicFormFieldType,
  FileField,
  NumberField,
  RadioField,
  SelectionListField,
  TextareaField,
  TextField
} from './index';

export const formFieldTypeDictionary: { [property in DynamicFormFieldType]: typeof DynamicFormField } = {
  [DynamicFormFieldType.TEXT]: TextField,
  [DynamicFormFieldType.NUMBER]: NumberField,
  [DynamicFormFieldType.DROPDOWN]: DropdownField,
  [DynamicFormFieldType.RADIO]: RadioField,
  [DynamicFormFieldType.DATE]: DateField,
  [DynamicFormFieldType.CHECKBOX]: CheckboxField,
  [DynamicFormFieldType.SELECTION_LIST]: SelectionListField,
  [DynamicFormFieldType.TEXTAREA]: TextareaField,
  [DynamicFormFieldType.FILE]: FileField,
  [DynamicFormFieldType.CALCULATOR]: CalculatorField,
  [DynamicFormFieldType.AUTOCOMPLETE]: AutocompleteField,
};
