import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { DynamicFormFieldOption, DynamicFormFieldWithOptions } from '../../../../models';
import { MatDialog } from '@angular/material/dialog';
import { OptionsTooltipModalComponent } from '../../options-tooltip-modal/options-tooltip-modal.component';
import { BaseDynamicFieldComponent } from '../base-dynamic-field/base-dynamic-field.component';

@Component({
  selector: 'ellipse-base-dynamic-field-with-options',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BaseDynamicFieldWithOptionsComponent extends BaseDynamicFieldComponent implements OnInit {

  @Input() override dynamicFormField!: DynamicFormFieldWithOptions;

  summaryFormattedValue!: string;

  constructor(private dialog: MatDialog) {
    super();
  }

  override ngOnInit() {
    super.ngOnInit();
    if (this.viewMode === 'summary') {
      this.generateSummaryFormattedValue();
    }
  }

  generateSummaryFormattedValue() {
    const formValue = this.dynamicFormField.formControl.value;
    const options = this.dynamicFormField.options;
    if (formValue instanceof Array) {
      this.convertValueArrayToCommaSeparatedLabels(formValue, options);
    } else {
      this.convertValueToLabel(formValue, options);
    }
  }

  private convertValueToLabel(formValue: string | number | boolean, options: DynamicFormFieldOption[]) {
    this.summaryFormattedValue = options.find(option => option.value === formValue)?.label ?? '';
  }

  private convertValueArrayToCommaSeparatedLabels(formValue: (string | number)[], options: DynamicFormFieldOption[]) {
    this.summaryFormattedValue = formValue.map(element => options.find(option => element === option.value)?.label).join(', ');
  }

  onShowOptionTooltips() {
    this.dialog.open(
      OptionsTooltipModalComponent, {
        data: {
          title: this.dynamicFormField.tooltip,
          options: this.dynamicFormField.options,
        }
      }
    );
  }

}
