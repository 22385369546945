import { DynamicFormArrayError } from './dynamic-form-array.error';
import { DynamicFormFieldOption } from '../../dynamic-form-field';
import { DynamicFormArray } from '../dynamic-form-array.model';

export class NoDuplicateValuesError extends DynamicFormArrayError {

  defaultErrorMsg = `Merci de renseigner une seule ligne par valeur pour le champ {{control}}: ({{duplicateValues}})`;

  constructor(details: { affectedControlNames?: string[], affectedValues?: any[], message?: string }) {
    super(details);
    this.message = this.setBaseMessage(details.message || this.defaultErrorMsg);
  }

  compileMessage(formArray: DynamicFormArray): string {
    const affectedControlOptions = this.getAffectedControlOptions(formArray);
    return this.affectedValues.reduce((compiledMessage, value) => compiledMessage.replace(`{{${ value }}}`, affectedControlOptions.find(option => option.value === value)?.label || ''), this.message);
  }

  private getAffectedControlOptions(formArray: DynamicFormArray): DynamicFormFieldOption[] {
    const affectedControl = formArray.findChildFieldConfigWithName(formArray.childPrototypeConfig, this.affectedControlNames[0]);
    return affectedControl?.options || [];
  }

  private setBaseMessage(message: string): string {
    return message.replace('{{control}}', this.affectedControlNames[0]).replace('{{duplicateValues}}', this.affectedValues.map(value => `{{${value}}}`).join(', '));
  }
}
