import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ellipse-dynamic-summary-field',
  templateUrl: './dynamic-summary-field.component.html',
  styleUrls: ['./dynamic-summary-field.component.scss']
})
export class DynamicSummaryFieldComponent implements OnInit {
  @Input() label!: string;
  @Input() value!: string | boolean | number;

  valueIsBoolean!: boolean;
  showSummaryField!: boolean;

  ngOnInit() {
    this.valueIsBoolean = typeof this.value === 'boolean';
    this.showSummaryField = this.value !== false;
  }
}
