import { DynamicFormField } from '../dynamic-form-field.model';
import { DynamicFormFieldConfig } from '../dynamic-form-field-config.model';
import { FormArray, FormGroup } from '@angular/forms';
import { DynamicTextFieldComponent } from '../../../components/dynamic-form/dynamic-form-field';

export class TextField extends DynamicFormField {
  constructor(config: DynamicFormFieldConfig, parentForm: FormGroup | FormArray) {
    super(config, parentForm);
    this.component = DynamicTextFieldComponent;
  }

  static override create(config: DynamicFormFieldConfig, parentForm: FormGroup | FormArray) {
    return new TextField(config, parentForm);
  }
}
