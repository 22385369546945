import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  BaseDynamicFieldComponent,
  BaseDynamicFieldWithOptionsComponent,
  CalculatorInputComponent,
  CalculatorModalComponent,
  DynamicCalculatorFieldComponent,
  DynamicCheckboxFieldComponent,
  DynamicDateFieldComponent,
  DynamicDropdownFieldComponent,
  DynamicFileFieldComponent,
  DynamicFormArrayActionsComponent,
  DynamicFormComponent,
  DynamicFormFieldContainerComponent,
  DynamicFormFieldsCardContainerComponent,
  DynamicFormFieldsContainerComponent,
  DynamicFormFieldsExpansionContainerComponent,
  DynamicNumberFieldComponent,
  DynamicRadioFieldComponent,
  DynamicSelectionListFieldComponent,
  DynamicSummaryFieldComponent,
  DynamicTextareaFieldComponent,
  DynamicTextFieldComponent,
  OptionsTooltipModalComponent
} from './components';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatCardModule } from '@angular/material/card';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { FieldHostDirective } from './directives/field-host.directive';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatListModule } from '@angular/material/list';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDateFnsModule } from '@angular/material-date-fns-adapter';
import { fr } from 'date-fns/locale';
import { EllipseIconModule } from 'ui-components';
import {
  DynamicAutocompleteFieldComponent
} from './components/dynamic-form/dynamic-form-field/dynamic-autocomplete-field/dynamic-autocomplete-field.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import {
  DynamicFormFieldsPlainContainerComponent
} from './components/dynamic-form/dynamic-form-fields-container/dynamic-form-fields-plain-container/dynamic-form-fields-plain-container.component';

@NgModule({
  declarations: [
    DynamicFormComponent,
    DynamicFormFieldContainerComponent,
    DynamicTextFieldComponent,
    DynamicNumberFieldComponent,
    BaseDynamicFieldComponent,
    DynamicDateFieldComponent,
    DynamicDropdownFieldComponent,
    DynamicRadioFieldComponent,
    OptionsTooltipModalComponent,
    FieldHostDirective,
    BaseDynamicFieldWithOptionsComponent,
    DynamicFormFieldsContainerComponent,
    DynamicFormFieldsCardContainerComponent,
    DynamicFormFieldsExpansionContainerComponent,
    DynamicFormArrayActionsComponent,
    DynamicCheckboxFieldComponent,
    DynamicSelectionListFieldComponent,
    DynamicTextareaFieldComponent,
    DynamicFileFieldComponent,
    DynamicCalculatorFieldComponent,
    CalculatorModalComponent,
    CalculatorInputComponent,
    DynamicSummaryFieldComponent,
    DynamicAutocompleteFieldComponent,
    DynamicFormFieldsPlainContainerComponent,
  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatRadioModule,
    MatCheckboxModule,
    MatCardModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    MatDialogModule,
    MatExpansionModule,
    MatListModule,
    MatDatepickerModule,
    MatDateFnsModule,
    EllipseIconModule,
    MatAutocompleteModule,
  ],
  exports: [
    DynamicFormComponent,
    DynamicFormFieldContainerComponent,
    DynamicCalculatorFieldComponent,
    CalculatorModalComponent,
    CalculatorInputComponent,
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: fr },
    { provide: MAT_DATE_FORMATS, useValue: {
        parse: {
          dateInput: 'yyyy-MM-dd',
        },
        display: {
          dateInput: 'yyyy-MM-dd',
          monthYearLabel: 'MMMM yyyy',
          dateA11yLabel: 'dd MMMM yyyy',
          monthYearA11yLabel: 'MMMM yyyy',
        },
      }
    },
  ]
})
export class EllipseDynamicFormModule {

}
