import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '@anxious-unicorn/env/environment';
import * as fromUi from '../state/ui/ui.reducer';
import { UiEffects } from '@anxious-unicorn/core/state/ui/ui.effects';
import * as fromLists from '../state/lists/lists.reducer';
import { ListsEffects } from '@anxious-unicorn/core/state/lists/lists.effects';
import { coreFeature } from '@anxious-unicorn/core/state/core.reducer';
import { CoreEffects } from '@anxious-unicorn/core/state/core.effects';

@NgModule({
  imports: [
    StoreModule.forRoot({
      [fromUi.featureKey]: fromUi.reducer,
      [fromLists.name]: fromLists.reducer,
      [coreFeature.name]: coreFeature.reducer
    }),
    EffectsModule.forRoot([UiEffects, ListsEffects, CoreEffects]),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production , connectInZone: true}),
  ]
})
export class NgrxModule {}
