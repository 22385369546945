import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import {
  DynamicFormArray,
  DynamicFormElement,
  DynamicFormElementTemplateType,
  DynamicFormElementType,
  DynamicFormField,
  DynamicFormGroup
} from '../../../models';
import { ViewMode } from '../../../models/view-mode.type';

@Component({
  selector: 'ellipse-dynamic-form-fields-container',
  templateUrl: './dynamic-form-fields-container.component.html',
  styleUrls: ['./dynamic-form-fields-container.component.scss']
})
export class DynamicFormFieldsContainerComponent implements OnInit {

  DynamicFormElementType = DynamicFormElementType;
  isArray!: boolean;
  isGroup!: boolean;
  templateIsCard!: boolean;
  templateIsExpansion!: boolean;
  templateIsPlain!: boolean;

  @Input() dynamicFormElement!: DynamicFormArray | DynamicFormGroup;
  @Input() parentIsArray: boolean = false;
  @Input() viewMode: ViewMode = 'form';

  @Output() elementDeleted = new EventEmitter<boolean>();

  @HostBinding('style.width') get width() {
    return `${ this.dynamicFormElement.width }%`;
  }

  ngOnInit() {
    this.setElementType(this.dynamicFormElement);
    this.setTemplateType(this.dynamicFormElement);
  }

  getDynamicFormFieldCollection(element: DynamicFormElement): DynamicFormGroup | DynamicFormArray {
    return element.elementType === DynamicFormElementType.GROUP ?
      element as DynamicFormGroup :
      element as DynamicFormArray;
  }

  getDynamicFormField(element: DynamicFormElement): DynamicFormField {
    return element as DynamicFormField;
  }

  onAddChild() {
    (this.dynamicFormElement as DynamicFormArray).addChild();
  }

  onRemoveChild(index: number) {
    (this.dynamicFormElement as DynamicFormArray).removeChild(index);
  }

  onClear() {
    (this.dynamicFormElement as DynamicFormArray).clearAllChildren();
  }

  onRevert() {
    (this.dynamicFormElement as DynamicFormArray).revert();
  }

  onDeleteSelf() {
    this.elementDeleted.emit(true);
  }

  private setElementType(element: DynamicFormElement) {
    switch (element.elementType) {
      case DynamicFormElementType.GROUP:
        this.isGroup = true;
        break;
      case DynamicFormElementType.ARRAY:
        this.isArray = true;
        break;
    }
  }

  private setTemplateType(element: DynamicFormArray | DynamicFormGroup) {
    switch (element.template) {
      case DynamicFormElementTemplateType.CARD:
        this.templateIsCard = true;
        break;
      case DynamicFormElementTemplateType.EXPANSION_PANEL:
        this.templateIsExpansion = true;
        break;
      case DynamicFormElementTemplateType.PLAIN:
        this.templateIsPlain = true;
        break;
    }
  }
}
