import { Component, Input } from '@angular/core';
import { DynamicFormElement } from '../../../../models';

@Component({
  selector: 'ellipse-dynamic-form-fields-card-container',
  templateUrl: './dynamic-form-fields-card-container.component.html',
  styleUrls: ['./dynamic-form-fields-card-container.component.scss']
})
export class DynamicFormFieldsCardContainerComponent {

  @Input() dynamicFormElement!: DynamicFormElement;

}
