import { DynamicFormFieldValidator } from '../dynamic-form-element-validator.model';
import { DynamicFormElementValidatorConfig } from '../dynamic-form-element-validator-config.model';
import { DynamicFormElementValidatorType } from '../dynamic-form-element-validator-type.enum';
import { Validators } from '@angular/forms';

export class MinLengthValidator extends DynamicFormFieldValidator {

  defaultErrorMsg = 'Ce champ doit contenir au moins {{value}} caractères.';

  constructor(config: DynamicFormElementValidatorConfig) {
    super(config);
    this.setType(DynamicFormElementValidatorType.MIN_LENGTH);
    this.setValue(config.value);
    this.configureError(config);
    this.setValidatorFn(Validators.minLength(this.value));
  }

  configureError(config: DynamicFormElementValidatorConfig) {
    this.errorMsg = config.errorMsg ?? this.defaultErrorMsg;
    this.interpolateValueInErrorMessage(this.value);
    this.errorKey = 'minlength';
  }

}
