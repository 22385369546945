import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ListsService } from '@anxious-unicorn/core/services/lists.service';
import { catchError, map, of, switchMap } from 'rxjs';
import * as ListsActions from './lists.actions';
import * as CoreActions from '../core.actions';

@Injectable()
export class ListsEffects {

  initializeApp$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CoreActions.InitializeApp),
      map(() => ListsActions.GetLists())
    );
  });

  getLists$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ListsActions.GetLists),
      switchMap(() => this.lists.getListsByName([
        'TypeUtilisateur',
        'Region',
        'Departement',
        'CentreType',
        'ApplicationSaisie',
        'CentreLabel',
        'SpecialiteResponsable',
        'CentreInclusion',
        'CentreMultisite',
        'CentreHdj',
        'Metier',
        'ActivitePedagogique',
        'TypeParticipation',
        'ProjetTypeEtude',
        'TypeCentreInvestigateur',
        'TypeProduction',
        'CentreCmrrCmActiviteSoutien',
        'CentreCmrrCmActiviteStimul',
        'CentreClTypeSpecialiste',
        'CentreClCollabNeuroPsy',
        'CentreClActiviteConsultMemoire',
        'CentreClCollabCmCmrr',
        'CentreClActiviteReseau'
      ])),
      map((lists) => ListsActions.GetListsSuccess({ lists })),
      catchError(error => of(ListsActions.GetListsFailure({ error: { message: error.message } })))
    );
  })

  constructor(private actions$: Actions,
              private lists: ListsService) {
  }
}
