import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NavMenuService } from '@anxious-unicorn/core/services/nav-menu.service';
import * as UiActions from '@anxious-unicorn/core/state/ui/ui.actions';
import * as CoreActions from '../core.actions';
import { catchError, combineLatest, map, of, switchMap } from 'rxjs';
import { AuthFacade } from 'auth-unicorn';
import { MainNavMenuItem, Permission } from 'types-unicorn';

@Injectable({
  providedIn: 'root'
})
export class UiEffects {

  initializeApp$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CoreActions.InitializeApp),
      map(() => UiActions.GetMainNavMenuItems())
    );
  });

  getMainNavMenuItems$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UiActions.GetMainNavMenuItems),
      switchMap(() => combineLatest([
        this.navMenu.getNavMenuItems(),
        this.auth.userPermissions()
      ])),
      map(([items, permissions]) => UiActions.GetMainNavMenuItemsSuccess({ menuItems: this.filterMenuItemsByUserPermissions(items, permissions) })),
      catchError(() => of(UiActions.GetMainNavMenuItemsFailure({
        error: { message: 'Une erreur est survenue lors de la récupération du menu de navigation' }
      })))
    );
  });

  modifyAccessRequestsItemBadge$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CoreActions.GetPendingAccessRequestsCountSuccess),
      map(({ count }) => UiActions.ModifyAccessRequestsItemBadge({ pending: count }))
    );
  });

  modifyNewResearchProjectsItemBadge$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CoreActions.GetNewResearchProjectsCountSuccess),
      map(({ count }) => UiActions.ModifyNewResearchProjectsItemBadge({ newProjects: count }))
    );
  });

  private filterMenuItemsByUserPermissions(items: MainNavMenuItem[], userPermissions: Permission[]) {
    return items.filter(item => !item.permissions || item.permissions.some(permission => userPermissions.includes(permission)));
  }

  constructor(private actions$: Actions,
              private navMenu: NavMenuService,
              private auth: AuthFacade) {
  }
}
