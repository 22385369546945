export enum DynamicFormFieldType {
  TEXT = 'TEXT',
  DATE = 'DATE',
  TEXTAREA = 'TEXTAREA',
  NUMBER = 'NUMBER',
  CHECKBOX = 'CHECKBOX',
  RADIO = 'RADIO',
  DROPDOWN = 'DROPDOWN',
  SELECTION_LIST = 'SELECTION_LIST',
  FILE = 'FILE',
  CALCULATOR = 'CALCULATOR',
  AUTOCOMPLETE = 'AUTOCOMPLETE',
}
