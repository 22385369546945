import {
  DynamicFormArray,
  DynamicFormArrayConfig,
  DynamicFormElement,
  DynamicFormElementConfig,
  DynamicFormElementType,
  DynamicFormFieldConfig,
  DynamicFormGroup,
  DynamicFormGroupConfig
} from '../models';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import { formFieldTypeDictionary } from '../models/dynamic-form-field/field-type.dictionary';

export class DynamicFormGeneratorUtils {

  static generateChildren(childConfigs: DynamicFormElementConfig[],
                          parentForm: FormGroup | FormArray): DynamicFormElement[] {
    return childConfigs.map(childConfig => {
      switch (childConfig.elementType) {
        case DynamicFormElementType.GROUP:
          return DynamicFormGeneratorUtils.generateFormGroup(childConfig, parentForm);
        case DynamicFormElementType.ARRAY:
          return DynamicFormGeneratorUtils.generateFormArray(childConfig, parentForm);
        case DynamicFormElementType.FIELD:
          return DynamicFormGeneratorUtils.generateFormField(childConfig, parentForm);
      }
    });
  }

  static generateFormField(childConfig: DynamicFormElementConfig, parentForm: FormGroup<any> | FormArray<any>) {
    const config = childConfig as DynamicFormFieldConfig;
    const fieldType = formFieldTypeDictionary[config.fieldType];
    return fieldType.create(config, parentForm);
  }

  static generateFormArray(config: DynamicFormElementConfig, parentForm: FormGroup<any> | FormArray<any>) {
    return DynamicFormArray.create(config as DynamicFormArrayConfig, parentForm);
  }

  static generateFormGroup(config: DynamicFormElementConfig, parentForm: FormGroup | FormArray) {
    return DynamicFormGroup.create(config as DynamicFormGroupConfig, parentForm);
  }

  static addFormElementToParent(formElement: AbstractControl, formElementName: string, parentForm: FormGroup | FormArray) {
    parentForm instanceof FormGroup ?
      parentForm.addControl(formElementName, formElement, { emitEvent: false }) :
      parentForm.push(formElement, { emitEvent: false });
  }

  static removeFormElementFromParent(formElementName: string, parentForm: FormGroup) {
    parentForm.removeControl(formElementName);
  }

  static preconfigureChildren(config: DynamicFormGroupConfig | DynamicFormArrayConfig) {
    return config.children.map(child => ({
      ...child,
      disabled: child.disabled || config.disabled,
      readonly: child.readonly || config.readonly
    }));
  }
}
