import { createReducer, on } from '@ngrx/store';
import * as UiActions from './ui.actions';
import { MainNavMenuItem } from 'types-unicorn';

export const featureKey = 'ui';

export interface UiState {
  menuOpen: boolean;
  mainNavMenuItems: MainNavMenuItem[];
  pendingAccessRequests: number;
  newResearchProjects: number;
}

export const initialState: UiState = {
  menuOpen: false,
  mainNavMenuItems: [],
  pendingAccessRequests: 0,
  newResearchProjects: 0
};

export const toggleSideMenu = (state: UiState): UiState => ({ ...state, menuOpen: !state.menuOpen });
export const setMainNavMenuItems = (state: UiState, action: { menuItems: MainNavMenuItem[] }): UiState =>
  ({ ...state, mainNavMenuItems: action.menuItems, menuOpen: true });

export const reducer = createReducer<UiState>(
  initialState,
  on(UiActions.ToggleSideMenu, toggleSideMenu),
  on(UiActions.GetMainNavMenuItemsSuccess, setMainNavMenuItems),
  on(UiActions.ModifyAccessRequestsItemBadge, (state, action): UiState => ({
    ...state,
    pendingAccessRequests: action.pending
  })),
  on(UiActions.ModifyNewResearchProjectsItemBadge, (state, action): UiState => ({
    ...state,
    newResearchProjects: action.newProjects
  }))
);

