import { FormArray, ValidatorFn } from '@angular/forms';
import { NoDuplicateValuesError } from '../../dynamic-form-array/errors/no-duplicate-values.error';

export const noDuplicateValues = (controlName: string, errorMsg: string = ''): ValidatorFn => {
  return (control: any): { noDuplicateValues: NoDuplicateValuesError } | null => {
    const formArray = control as FormArray;
    return validate(formArray, controlName, errorMsg);
  };
};

function validate(formArray: FormArray, affectedControlName: string, message: string): {
  noDuplicateValues: NoDuplicateValuesError
} | null {
  const duplicates = findDuplicatesForControl(formArray, affectedControlName);
  return duplicates.length > 0 ?
    {
      noDuplicateValues: new NoDuplicateValuesError({
        affectedControlNames: [affectedControlName],
        affectedValues: duplicates,
        message
      })
    } : null;
}

function findDuplicatesForControl(formArray: FormArray, controlName: string): string[] {
  const valuesForControl = getValuesForControl(formArray, controlName);
  const totalsPerValue = getTotalsPerValue(valuesForControl);
  return valuesWithDuplicates(totalsPerValue);
}

function getValuesForControl(formArray: FormArray, controlName: string): any[] {
  return formArray.value.map((item: {
    [key: string]: any
  }) => item[controlName]).filter((value: any) => !!value);
}

function getTotalsPerValue(valuesForControl: any[]): { [value: string]: number } {
  return valuesForControl.reduce((totals: { [key: string]: number }, value: any) => ({
    ...totals,
    [value.toString()]: (totals[value] || 0) + 1
  }), {});
}

function valuesWithDuplicates(totalsPerValue: { [key: string]: number }): string[] {
  return Object.keys(totalsPerValue).filter((key: string) => totalsPerValue[key] > 1);
}
