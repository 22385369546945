import { List } from 'types-unicorn';
import { Dictionary } from '@ngrx/entity';

export class ListsUtils {
  static createListDictionary(lists: List[]): Dictionary<List> {
    return lists.reduce((acc, curr) => ({
      ...acc,
      [curr.uniqueKey]: curr
    }), {});
  }
}
