import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {
  BaseDynamicFieldWithOptionsComponent
} from '../base-dynamic-field-with-options/base-dynamic-field-with-options.component';

@Component({
  selector: 'ellipse-dynamic-dropdown-field',
  templateUrl: './dynamic-dropdown-field.component.html',
  styleUrls: ['./dynamic-dropdown-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicDropdownFieldComponent extends BaseDynamicFieldWithOptionsComponent implements OnInit {

  override ngOnInit() {
    super.ngOnInit();
    this.disableDropdownIfFieldReadonly();
  }

  onClearDropdown(event: MouseEvent) {
    event.stopPropagation();
    this.dynamicFormField.clear();
  }

  private disableDropdownIfFieldReadonly() {
    if (this.dynamicFormField.readonly) {
      this.dynamicFormField.formControl.disable();
    }
  }
}
