import { DynamicFormFieldValidator } from '../dynamic-form-element-validator.model';
import { DynamicFormElementValidatorConfig } from '../dynamic-form-element-validator-config.model';
import { DynamicFormElementValidatorType } from '../dynamic-form-element-validator-type.enum';
import { Validators } from '@angular/forms';

export class MinValueValidator extends DynamicFormFieldValidator {

  defaultErrorMsg = 'La valeur minimale pour ce champ est de {{value}}.';

  constructor(config: DynamicFormElementValidatorConfig) {
    super(config);
    this.setType(DynamicFormElementValidatorType.MIN_VALUE);
    this.setValue(config.value);
    this.configureError(config);
    this.setValidatorFn(Validators.min(this.value));
  }

  configureError(config: DynamicFormElementValidatorConfig) {
    this.errorMsg = config.errorMsg ?? this.defaultErrorMsg;
    this.interpolateValueInErrorMessage(this.value);
    this.errorKey = 'min';
  }

}
