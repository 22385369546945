import { AbstractControl, FormGroup } from '@angular/forms';
import { DynamicFormGeneratorUtils } from '../utils/dynamic-form-generator.utils';
import { DynamicFormElement, DynamicFormElementConfig } from './dynamic-form-element';
import { DynamicFormConfig } from './dynamic-form-config.model';
import { DynamicFormField } from './dynamic-form-field';
import { DynamicFormUtils } from '../utils/dynamic-form.utils';
import { DynamicFormLink, DynamicFormLinkType } from './dynamic-form-links';
import { Observable, startWith, Subject, takeUntil, tap } from 'rxjs';
import { ExistsForValueLink } from './dynamic-form-links/link-types/exists-for-value-link.model';
import { ExistsForCustomPropertyLink } from './dynamic-form-links/link-types/exists-for-custom-property-link.model';
import { DynamicFormConfigCreateOptions } from './dynamic-form-config-create-options.model';
import { DynamicFormArray } from './dynamic-form-array';

export class DynamicForm {

  label!: string;
  name!: string;
  elementConfigs!: DynamicFormElementConfig[];
  buttons!: { submit: { label: string, position?: string } };
  links: DynamicFormLink[] = [];
  labelIcon?: string;
  rootFormGroup!: FormGroup;
  elements!: DynamicFormElement[];
  elementCatalogue: { [key: string]: DynamicFormElement } = {};
  customProperties: { [key: string]: string | number | boolean } = {};

  private destroy$!: Subject<boolean>;

  private constructor(config: DynamicFormConfig) {
    this.createBaseFields(config);
    this.buildElements(config);
    this.addElementsToCatalogue();
    this.setCustomProperties(config);
    this.establishLinks(config);
  }

  static create(config: DynamicFormConfig, options: DynamicFormConfigCreateOptions = new DynamicFormConfigCreateOptions()) {
    const form = new DynamicForm(config);
    if (options.readonly) {
      form.rootFormGroup.disable();
    }
    return form;
  }

  clear() {
    DynamicFormUtils.clear(this.elements);
  }

  revert() {
    this.elements.forEach(element => element.revert());
  }

  patch(formValue: any) {
    this.setControlsToArraysForFormValue(formValue);
    this.rootFormGroup.patchValue(formValue);
    this.patchDynamicallyCreatedControls(formValue);
  }

  private setControlsToArraysForFormValue(formValue: { [key: string]: any }): void {
    Object.keys(formValue).forEach(key => {
      if (Array.isArray(formValue[key])) {
        const formArray = this.elementCatalogue[key] as DynamicFormArray;
        for (let item of formValue[key]) {
          formArray.addChild();
        }
      }
    });
  }

  destroy() {
    this.destroy$.next(true);
  }

  private setCustomProperties(config: DynamicFormConfig) {
    if (config.customProperties) {
      this.customProperties = config.customProperties;
    }
  }

  private patchDynamicallyCreatedControls(formValue: any) {
    const flattenedFormValue = this.flattenFormValue(formValue);
    const foundLinks = this.getAffectedLinks(flattenedFormValue);
    if (foundLinks.length === 0) {
      return;
    }
    const linkChildrenNames = this.getAllChildNamesForLinks(foundLinks);
    this.patchAffectedControls(linkChildrenNames, flattenedFormValue);
  }

  private patchAffectedControls(linkChildrenNames: string[], flattenedFormValue: { [p: string]: any }) {
    const affectedControls = this.getElementsByNameFromCatalogue(linkChildrenNames);
    affectedControls.forEach(control => (control as DynamicFormField).formControl.patchValue(flattenedFormValue[control.name]));
  }

  private getAllChildNamesForLinks(foundLinks: ExistsForValueLink[]) {
    return foundLinks.reduce((acc, curr) => ([
      ...acc,
      ...curr.childElements
    ]), [] as string[]);
  }

  private getAffectedLinks(flattenedFormValue: { [p: string]: any }) {
    return (this.links.filter(link =>
        DynamicFormLink.isExistsForValueLink(link) &&
        Object.keys(flattenedFormValue).includes(link.parentControl))
    ) as ExistsForValueLink[];
  }

  private createBaseFields(config: DynamicFormConfig) {
    this.destroy$ = new Subject<boolean>();
    this.label = config.label;
    this.name = config.name;
    this.elementConfigs = config.elementConfigs;
    this.buttons = config.buttons;
    this.links = config.links ?? [];
    this.rootFormGroup = new FormGroup({});
    if (config.labelIcon) {
      this.labelIcon = config.labelIcon;
    }
  }

  private buildElements(config: DynamicFormConfig): void {
    this.elements = DynamicFormGeneratorUtils.generateChildren(config.elementConfigs, this.rootFormGroup);
  }

  private addElementsToCatalogue() {
    this.elements.forEach(element => this.addElementToCatalogue(element));
  }

  private flattenFormValue(formValue: any): { [key: string]: any } {
    return Object.entries(formValue).reduce((acc, [key, value]) => typeof value === 'object' ? { ...acc, ...this.flattenFormValue(value) } : {
      ...acc,
      [key]: value
    }, {});
  }

  private establishLinks(config: DynamicFormConfig) {
    if (!config.links || config.links.length === 0) {
      return;
    }
    config.links.forEach(link => {
      switch (link.linkType) {
        case DynamicFormLinkType.EXISTS_FOR_VALUES:
          this.createExistsForValueLink(link as ExistsForValueLink);
          break;
        case DynamicFormLinkType.EXISTS_FOR_CUSTOM_PROPERTY:
          this.createExistsForCustomPropertyLink(link as ExistsForCustomPropertyLink);
          break;
        default:
          return;
      }
    });
  }

  private createExistsForValueLink(link: ExistsForValueLink) {
    const parentControl = this.getParentControlFromCatalogue(link);
    const childElements = this.getElementsByNameFromCatalogue(link.childElements);
    const parentControlValueListener$ = this.createFormControlValueListener(parentControl).pipe(
      tap(value => childElements.forEach((element: DynamicFormElement) => this.addOrRemoveElementForLinkValues(link, value, element)))
    );
    parentControlValueListener$.subscribe();
  }

  private createExistsForCustomPropertyLink(link: ExistsForCustomPropertyLink) {
    const customPropertyValue = this.customProperties[link.propertyName];
    if (customPropertyValue === undefined) {
      console.warn(`Custom property ${ link.propertyName } not set on form.`);
      return;
    }
    const affectedElements = this.getElementsByNameFromCatalogue(link.affectedControlNames);
    affectedElements.forEach((element: DynamicFormElement) => this.addOrRemoveElementForLinkValues(link, customPropertyValue, element));
  }

  private addOrRemoveElementForLinkValues(link: DynamicFormLink, value: any, element: DynamicFormElement) {
    link.values!.includes(value) ? element.addSelf() : element.removeSelf();
  }

  private getElementsByNameFromCatalogue(elementNames: string[]): DynamicFormElement[] {
    return elementNames.map(name => this.elementCatalogue[name]);
  }

  private getParentControlFromCatalogue(link: ExistsForValueLink) {
    return (this.elementCatalogue[link.parentControl] as DynamicFormField).formControl;
  }

  private createFormControlValueListener(control: AbstractControl): Observable<any> {
    return control.valueChanges.pipe(
      takeUntil(this.destroy$),
      startWith(control.value)
    );
  }

  private addElementToCatalogue(element: DynamicFormElement) {
    if (DynamicFormUtils.isDynamicFormGroup(element)) {
      element.children.forEach(child => this.addElementToCatalogue(child));
    }
    this.elementCatalogue[element.name] = element;
  }
}
