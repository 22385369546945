import { Component, Inject } from '@angular/core';
import {  MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DynamicFormFieldOption } from '../../../models';

@Component({
  selector: 'ellipse-options-tooltip-modal',
  templateUrl: './options-tooltip-modal.component.html',
  styleUrls: ['./options-tooltip-modal.component.scss']
})
export class OptionsTooltipModalComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: { title: string, options: DynamicFormFieldOption[] }) { }
}
