<mat-expansion-panel expanded #panel [hideToggle]="!dynamicFormElement.closable">
  <mat-expansion-panel-header *ngIf="dynamicFormElement.label"
                              [ngClass]="{ 'cancel-pointer': !dynamicFormElement.closable }">
    <mat-panel-title [ngClass]="{ 'error-text': dynamicFormElement.hasError() }">
      <ellipse-icon *ngIf="dynamicFormElement.labelIcon as iconName" [iconName]="iconName"></ellipse-icon>
      {{ dynamicFormElement.label }}
    </mat-panel-title>
    <mat-panel-description
      *ngIf="dynamicFormElement.description && !panel.expanded">{{ dynamicFormElement.description }}</mat-panel-description>
  </mat-expansion-panel-header>
  <ng-content select="[formContent]"></ng-content>
  <div class="panel-buttons">
    <ng-content select="[formArrayActions]"></ng-content>
  </div>
</mat-expansion-panel>
