import { DynamicFormElementType } from './dynamic-form-element-type.enum';
import { DynamicFormElementValidatorConfig } from '../dynamic-form-validators';
import { DynamicFormElementTemplateType } from './dynamic-form-element-template-type.enum';

export class DynamicFormElementConfig {
  public name!: string;
  public label?: string;
  public elementType!: DynamicFormElementType;
  public validators?: DynamicFormElementValidatorConfig[];
  public readonly?: boolean;
  public disabled?: boolean;
  public cssClasses?: string[];
  public width?: number;
  public wholeRow?: boolean;
  public tooltip?: string;
  public description?: string;
  public closable?: boolean;
  public template?: DynamicFormElementTemplateType;
  public labelIcon?: string;
  public hideInSummaryIfNull?: boolean;
  public showForPermissions?: string[];
  public editableForPermissions?: string[];
}
