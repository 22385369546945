import { Component, OnInit } from '@angular/core';
import { BaseDynamicFieldComponent } from '../base-dynamic-field/base-dynamic-field.component';
import { map, Observable, startWith, takeUntil } from 'rxjs';

@Component({
  selector: 'ellipse-dynamic-file-field',
  templateUrl: './dynamic-file-field.component.html',
  styleUrls: ['./dynamic-file-field.component.scss']
})
export class DynamicFileFieldComponent extends BaseDynamicFieldComponent implements OnInit {
  filenames$!: Observable<string>;

  override ngOnInit() {
    super.ngOnInit();
    this.filenames$ = this.dynamicFormField.formControl.valueChanges.pipe(
      startWith(this.dynamicFormField.formControl.value),
      takeUntil(this.destroy$),
      map(value => {
        const files = value as FileList ?? [];
        const filenamesCollection = [];
        for (let i = 0; i < files.length; i++) {
          filenamesCollection.push(files[i].name);
        }
        return filenamesCollection.join(',\n');
      })
    );
  }

  onFileChange(event: Event) {
    const input = event.target as HTMLInputElement;
    if (!input.files || input.files.length === 0) {
      return;
    }
    this.dynamicFormField.formControl.patchValue(input.files ?? []);
  }
}
