import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import * as CoreActions from './core.actions';
import { catchError, filter, map, of, switchMap, tap } from 'rxjs';
import { DialogUnicornService } from 'dialog-unicorn';
import { BadgeService } from '@anxious-unicorn/core/services/badge.service';
import { Injectable } from '@angular/core';
import { AuthFacade } from 'auth-unicorn';

@Injectable()
export class CoreEffects {

  initPendingAccessRequestsCount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CoreActions.InitializeApp),
      map(() => CoreActions.GetPendingAccessRequestsCount())
    );
  });

  initNewResearchProjectsCount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CoreActions.InitializeApp),
      map(() => CoreActions.GetNewResearchProjectsCount())
    );
  });

  getPendingAccessRequestsCount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        CoreActions.GetPendingAccessRequestsCount,
        CoreActions.GetPendingAccessRequestCountFromAccessRequestsFeature,
      ),
      concatLatestFrom(() => this.auth.userHasPermission('bnaDemandeAccesCRU')),
      filter(([_, userHasPermission]) => !!userHasPermission),
      switchMap(() => this.badge.getPendingAccessRequestsCount().pipe(
        map(({ count }) => CoreActions.GetPendingAccessRequestsCountSuccess({ count })),
        catchError(error => of(CoreActions.GetPendingAccessRequestsCountFailure({ error })))
      ))
    );
  });

  getNewResearchProjectsCount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        CoreActions.GetNewResearchProjectsCount,
        CoreActions.GetNewResearchProjectsCountFromResearchProjectsFeature,
      ),
      concatLatestFrom(() => this.auth.userHasPermission('bnaProjetRechercheCRU')),
      filter(([_, userHasPermission]) => !!userHasPermission),
      switchMap(() => this.badge.getNewResearchProjectsCount().pipe(
        map(({ count }) => CoreActions.GetNewResearchProjectsCountSuccess({ count })),
        catchError(error => of(CoreActions.GetNewResearchProjectsCountFailure({ error })))
      ))
    );
  });

  showErrorDialog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CoreActions.GetPendingAccessRequestsCountFailure),
      tap(error => this.dialog.showErrorDialog(error))
    );
  }, { dispatch: false });

  constructor(private actions$: Actions,
              private badge: BadgeService,
              private dialog: DialogUnicornService,
              private auth: AuthFacade) {
  }
}
