<div class="container" *ngIf="viewMode === 'form'">
  <mat-form-field>
    <mat-label>{{ dynamicFormField.label }}</mat-label>
    <ellipse-icon *ngIf="!required && !dynamicFormField.readonly"
                  class="clear-icon"
                  matSuffix
                  (click)="onClearDropdown($event)"
                  iconName="fa-regular fa-xmark"
                  iconSize="lg" />
    <mat-select [formControl]="dynamicFormField.formControl">
      <mat-option *ngFor="let option of dynamicFormField.options$ | async" [value]="option.value">{{ option.label }}</mat-option>
    </mat-select>
    <mat-error *ngIf="errorMsg$ | async as errorMsg">{{ errorMsg }}</mat-error>
  </mat-form-field>
  <ellipse-icon *ngIf="dynamicFormField.showOptionTooltips"
           (click)="onShowOptionTooltips()"
           iconName="fa-regular fa-circle-info"
           iconSize="xl" />
</div>
<ellipse-dynamic-summary-field *ngIf="viewMode === 'summary'" [label]="dynamicFormField.label" [value]="summaryFormattedValue"/>
