<fieldset class="radio-group-container" *ngIf="viewMode === 'form'">
  <legend [id]="dynamicFormField.name">{{ dynamicFormField.label }}</legend>
  <mat-radio-group [formControl]="dynamicFormField.formControl">
    <mat-radio-button *ngFor="let option of dynamicFormField.options$ | async"
                      [value]="option.value">{{ option.label }}</mat-radio-button>
  </mat-radio-group>
  <ellipse-icon *ngIf="dynamicFormField.showOptionTooltips"
                (click)="onShowOptionTooltips()"
                iconName="fa-regular fa-circle-info"
                iconSize="xl"/>
</fieldset>
<ellipse-dynamic-summary-field *ngIf="viewMode === 'summary'" [label]="dynamicFormField.label" [value]="summaryFormattedValue"/>
