import { Component } from '@angular/core';
import { BaseDynamicFieldComponent } from '../base-dynamic-field/base-dynamic-field.component';

@Component({
  selector: 'ellipse-dynamic-textarea-field',
  templateUrl: './dynamic-textarea-field.component.html',
  styleUrls: ['./dynamic-textarea-field.component.scss']
})
export class DynamicTextareaFieldComponent extends BaseDynamicFieldComponent {
}
