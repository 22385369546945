import { List } from 'types-unicorn';
import * as ListsActions from './lists.actions';
import { createFeature, createReducer, on } from '@ngrx/store';
import { BnaError } from 'types-unicorn';
import { Dictionary } from '@ngrx/entity';
import { ListsUtils } from '@anxious-unicorn/core/utils/lists.utils';

export interface ListsState {
  listDictionary: Dictionary<List>;
  error: BnaError | null;
}

export const initialState: ListsState = {
  listDictionary: {},
  error: null
};

export const featureKey = 'lists';

export const listsFeature = createFeature({
  name: featureKey,
  reducer: createReducer(
    initialState,
    on(ListsActions.GetListsSuccess, (state, action): ListsState => ({
      ...state,
      listDictionary: ListsUtils.createListDictionary(action.lists)
    })),
    on(ListsActions.GetListsFailure, (state, action): ListsState =>
      ({ ...state, error: action.error })
    )
  )
});

export const {
  name,
  reducer,
  selectListDictionary
} = listsFeature;
