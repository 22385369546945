import { DynamicFormFieldValidator } from '../dynamic-form-element-validator.model';
import { DynamicFormElementValidatorConfig } from '../dynamic-form-element-validator-config.model';
import { DynamicFormElementValidatorType } from '../dynamic-form-element-validator-type.enum';
import { noDuplicateValues } from '../custom-validator-functions/no-duplicate-values.validator';

export class NoDuplicateValuesValidator extends DynamicFormFieldValidator {
  defaultErrorMsg = 'Les valeurs ne peuvent être répétées.';
  constructor(config: DynamicFormElementValidatorConfig) {
    super(config);
    this.setType(DynamicFormElementValidatorType.NO_DUPLICATE_VALUES_FOR_CONTROL);
    this.setValue(config.value);
    this.configureError(config);
    this.setValidatorFn(noDuplicateValues(this.value, this.errorMsg));
  }

  configureError(config: DynamicFormElementValidatorConfig) {
    this.errorMsg = config.errorMsg ?? this.defaultErrorMsg;
    this.errorKey = 'noDuplicateValues';
  }
}
