import { DynamicFormElementValidatorConfig } from './dynamic-form-element-validator-config.model';
import { validatorTypeDictionary } from './validator-type.dictionary';
import { DynamicFormFieldValidator } from './dynamic-form-element-validator.model';

export class DynamicFormElementValidatorFactory {

  public create(config: DynamicFormElementValidatorConfig): DynamicFormFieldValidator {
    const validatorType = config.type;
    const validatorClass = validatorTypeDictionary[validatorType];
    return new validatorClass(config);
  }

}
